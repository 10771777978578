import React, { useState, useEffect } from 'react';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import styled from 'pwm-components/styled';

import Box from 'pwm-components/components/Box';
import { createInput } from 'pwm-components/components/Input/InputWrapper';
import StyledInputField, { Props as SIProps } from 'pwm-components/components/Input/StyledInputField';
import StyledInputFieldWrapper, { Props as SIWProps } from 'pwm-components/components/Input/StyledInputFieldWrapper';
import Input from 'pwm-components/components/Input';
import Paragraph from 'pwm-components/components/Paragraph';
import StyledLabel from 'pwm-components/components/Input/StyledLabel';
import Text from 'pwm-components/components/Text';
import TextButton from 'pwm-components/components/TextButton';
import { withTooltip } from 'pwm-components/components/Tooltip';

import LoginIcon from 'pwm-components/icons/Login';
import Tick from 'pwm-components/icons/Tick';
import Cross from 'pwm-components/icons/Cross';

import { Subdomain } from '@avira-pwm/sync/ModelSpecifics/Account';
import ServerDate from '@avira-pwm/helpers/ServerDate';
import { constructValidUrl } from '@avira-pwm/helpers/url';
import {
  getSubdomain,
  getDomain,
  isValidURL,
} from '../lib/DomainNameHelper';

type Props = WrappedComponentProps & SIWProps & SIProps & {
  id: string;
  tabIndex: number;
  domain: string;
  label: string;
  placeholder: string;
  error: string | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icons: JSX.Element | null;
  linkedUrls: Array<Subdomain>;
  showDropdownMenu: boolean;
  closeLinkedUrls: () => void;
  onAccountChange: (
    key: string,
    accountSubdomains: Array<Subdomain>
  ) => void;
  onLoginClick: (
    e: React.MouseEvent,
    linkedUrlEntry: Subdomain,
  ) => void;
  onAssociatedUrlAddClicked: (tracking: object) => void;
  onAssociatedUrlRemoveClicked: (tracking: object) => void;
  onAssociatedUrlNavigateClicked: (tracking: object) => void;
};

type LinkedUrlBoxProps = {
  collapsed: boolean;
  maxHeight: number;
};

const LinkedUrlListItemBase = styled('li')`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  text-align: left;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.alto};
  }

  color: ${({ theme: { colors } }) => colors.martinique};

  &: not(: last-child) {
    border-bottom: solid 1px ${({ theme: { colors } }) => colors.alabaster};
  }
`;

const LinkedUrlListItemNavigate = styled(Box)`
  flex: 1 1 auto;
  align-items: center;
  padding: ${({ theme: { space } }) => `${space.m} ${space.m} ${space.m} ${space.s}`};
  cursor: pointer;
  &:hover {
    color: ${({ theme: { colors } }) => colors.royalblue};
    ${LoginIcon} {
      color: ${({ theme: { colors } }) => colors.royalblue};
      fill: ${({ theme: { colors } }) => colors.royalblue};
    }
  }
`;

const LinkedUrlListItemRemove = styled(Box)`
  align-items: center;
  padding: ${({ theme: { space } }) => `${space.m} ${space.s}`};
  cursor: pointer;
  &:hover {
    color: ${({ theme: { colors } }) => colors.royalblue};
    ${Cross} {
      color: ${({ theme: { colors } }) => colors.royalblue};
      fill: ${({ theme: { colors } }) => colors.royalblue};
    }
  }
`;

const NewAssociatedUrlInputBox = styled(Box)`
  border-color: ${({ theme: { mode, colors } }) => (mode === 'light' ? colors.gray : colors.mako)};
`;

const LinkedUrlList = styled('ul')`
  overflow: auto;
  border-radius: ${({ theme: { space } }) => space.xxs};
  border: 1px solid ${({ theme: { colors } }) => colors.gray};
  background: ${({ theme: { colors } }) => colors.white};
`;

const InputWithoutBorder = styled(Input)`
  border: none;
`;

const LinkedUrlBox = styled('div')<LinkedUrlBoxProps>`
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  border-radius: ${({ theme: { space } }) => space.xxs};
  box-shadow: 0 20px 20px 0 rgba(0,0,0,0.2);
  max-height: ${({
    collapsed,
    maxHeight,
  }) => (collapsed ? 0 : `${maxHeight}px`)};
  transition: max-height ${({
    theme: { transition },
    collapsed,
  }) => (collapsed ? 0 : transition.duration.long)};
`;

const UrlInput = createInput(styled.span`
${StyledInputField}{
  padding-left: ${({ theme: { space } }) => space.s};
  padding-right: 2px;
  width: 100%;
}
`);

const Wrapper = styled(StyledInputFieldWrapper)`
  display: flex;
  align-items: center;
  align-content: center;
`;

const LoginIconWithTooltip = withTooltip(LoginIcon);
const DeleteIconWithTooltip = withTooltip(Cross);

const TRACKING_CONTEXT = 'details';

const DomainInputWithAssociatedUrls: React.FC<Props> = ({
  intl,
  id,
  tabIndex,
  domain,
  label,
  placeholder,
  onChange,
  icons,
  linkedUrls,
  showDropdownMenu,
  closeLinkedUrls,
  onAccountChange,
  onLoginClick,
  onAssociatedUrlAddClicked,
  onAssociatedUrlRemoveClicked,
  onAssociatedUrlNavigateClicked,

  error,
  disabled,
  focused,
  readOnly,
  warning,
  variant,

  ref,
  ...rest
}) => {
  const maxHeight = 200;
  const accLinkedUrls = (
    linkedUrls ? (linkedUrls.filter((linkedUrl: Subdomain) => linkedUrl?.visible)) : []
  );

  const totalAssociatedUrls = accLinkedUrls.length;

  const [addNewLinkedUrlInput, setAddNewLinkedUrlInput] = useState(false);
  const [newLinkedUrl, setnewLinkedUrl] = useState('');
  const [errorState, setErrorState] = useState('');

  const onAddLinkedUrlInput = (): void => {
    if (newLinkedUrl === '') {
      return;
    }

    const checknewLinkedUrl = isValidURL(newLinkedUrl);

    if (!checknewLinkedUrl) {
      setErrorState(intl.formatMessage({ id: 'dashboard.account.details.linkedUrl.error.domain.format' }));
      return;
    }

    const now = new ServerDate().toISOString();
    const accountSubdomains = JSON.parse(JSON.stringify(linkedUrls || []));
    let linkedUrlAlreadyExists = false;

    accountSubdomains.forEach((entry: Subdomain) => {
      if (getSubdomain(entry.subdomain) === getSubdomain(newLinkedUrl)) {
        entry.visible = true;
        linkedUrlAlreadyExists = true;
      }
    });

    if (!linkedUrlAlreadyExists) {
      const newLinkedUrlEntry = {
        subdomain: getSubdomain(constructValidUrl(newLinkedUrl)),
        visible: true,
        lastUsedAt: now,
      };
      accountSubdomains.push(newLinkedUrlEntry);
    }

    onAssociatedUrlAddClicked({
      domain,
      associatedDomain: getDomain(newLinkedUrl),
      subdomain: getSubdomain(newLinkedUrl),
      totalAssociatedUrls,
      context: TRACKING_CONTEXT,
    });

    onAccountChange('subdomain', accountSubdomains);
    setAddNewLinkedUrlInput(false);
  };

  const onRemoveLinkedUrl = (subdomainEntry: Subdomain): void => {
    const accountSubdomains = JSON.parse(JSON.stringify(linkedUrls));
    const updatedSubdomains = accountSubdomains.map((entry: Subdomain) => {
      if (entry.subdomain === subdomainEntry.subdomain) {
        entry.visible = false;
      }
      return entry;
    });

    onAssociatedUrlRemoveClicked({
      domain,
      associatedDomain: getDomain(newLinkedUrl),
      subdomain: getSubdomain(newLinkedUrl),
      totalAssociatedUrls,
      context: TRACKING_CONTEXT,
    });

    onAccountChange('subdomain', updatedSubdomains);
  };

  const onNavigate = (
    e: React.MouseEvent,
    linkedUrlEntry: Subdomain,
  ): void => {
    closeLinkedUrls();
    onAssociatedUrlNavigateClicked({
      domain,
      subdomain: linkedUrlEntry.subdomain,
      context: TRACKING_CONTEXT,
    });
    onLoginClick(
      e,
      linkedUrlEntry,
    );
  };

  const addAssociatedUrlVieEnter = (e: React.KeyboardEvent): void => {
    switch (e.keyCode) {
      case 13:
        onAddLinkedUrlInput();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const showHideSuggestionList = (): void => {
      setAddNewLinkedUrlInput(false);
      setErrorState('');

      closeLinkedUrls();
    };

    window.addEventListener('click', showHideSuggestionList);
    return () => window.removeEventListener('click', showHideSuggestionList);
  }, [closeLinkedUrls]);

  return (
    <Box
      position="relative"
      width="100%"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <StyledLabel>
        {label}
      </StyledLabel>
      <Wrapper
        error={error}
        disabled={disabled}
        focused={focused}
        readOnly={readOnly}
        warning={warning}
        variant={variant}
      >
        <UrlInput
          id={id}
          value={domain}
          tabIndex={tabIndex}
          placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />
        <Box style={{ marginRight: '2px' }}>
          {icons}
        </Box>
      </Wrapper>
      <LinkedUrlBox
        collapsed={!showDropdownMenu}
        maxHeight={maxHeight}
      >
        <LinkedUrlList className="a-subdomains-list" style={{ maxHeight }}>
          {accLinkedUrls.map((linkedUrlEntry, index) => (
            <LinkedUrlListItemBase className={`a-subdomain-component-${index}`} key={linkedUrlEntry.subdomain}>
              <LinkedUrlListItemNavigate
                display="flex"
                alignItems="center"
                minWidth="0px"
                onClick={(e) => {
                  e.stopPropagation();
                  onNavigate(e, linkedUrlEntry);
                }}
              >
                <Box className="a-subdomain" minWidth="0px" flex="1 1 auto">
                  <Text
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      display: 'block',
                    }}
                  >
                    {getSubdomain(linkedUrlEntry.subdomain)}
                  </Text>
                </Box>
                <LoginIconWithTooltip
                  className="a-redirect-subdomain-action"
                  info
                  variant="dark"
                  tooltipAlign="right"
                  tooltipContent={(
                    <FormattedMessage
                      id="dashboard.account.details.linkedUrl.login"
                      defaultMessage="Login"
                    />
                  )}
                />
              </LinkedUrlListItemNavigate>
              <LinkedUrlListItemRemove
                className="a-delete-subdomain-action"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveLinkedUrl(linkedUrlEntry);
                }}
              >
                <DeleteIconWithTooltip
                  info
                  variant="dark"
                  tooltipAlign="right"
                  tooltipContent={(
                    <FormattedMessage
                      id="dashboard.account.details.linkedUrl.Delete"
                      defaultMessage="Delete"
                    />
                  )}
                />
              </LinkedUrlListItemRemove>
            </LinkedUrlListItemBase>
          ))}
          {
            accLinkedUrls.length === 0 && (
              <Box>
                <Paragraph mt="s" mx="m" variant="dark">
                  <FormattedHTMLMessage id="dashboard.account.details.linkedUrl.noLinkedUrl.header" />
                </Paragraph>
                <Paragraph mt="m" mx="m" variant="dark">
                  <FormattedMessage id="dashboard.account.details.linkedUrl.noLinkedUrl.1" />
                </Paragraph>
                <Paragraph mb="xl" mx="m" variant="dark">
                  <FormattedMessage id="dashboard.account.details.linkedUrl.noLinkedUrl.2" />
                </Paragraph>
              </Box>
            )
          }
          <Box>
            {
              addNewLinkedUrlInput && (
                <NewAssociatedUrlInputBox className="a-subdomain-input" borderTop borderBottom py="xxs">
                  <InputWithoutBorder
                    autoComplete="off"
                    autoFocus
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => setnewLinkedUrl(
                        e.target.value.trim(),
                      )
                    }
                    icons={[
                      <TextButton
                        className="a-save-subdomain-input-action"
                        mr="m"
                        onClick={(e) => {
                          e.stopPropagation();
                          onAddLinkedUrlInput();
                        }}
                        key="tick" // Fixes double key warning
                      >
                        <Tick variant="dark" />
                      </TextButton>,
                      <TextButton
                        className="a-clear-subdomain-input-action"
                        pr="xxs"
                        onClick={(e) => {
                          e.stopPropagation();
                          setnewLinkedUrl('');
                          setAddNewLinkedUrlInput(false);
                        }}
                        key="cross" // Fixes double key warning
                      >
                        <Cross variant="dark" />
                      </TextButton>,
                    ]}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      addAssociatedUrlVieEnter(e);
                    }}
                    error={errorState}
                  />
                </NewAssociatedUrlInputBox>
              )
            }
            <TextButton
              className="a-add-subdomain-action"
              mx="s"
              my="m"
              tabIndex={0}
              variant="link"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setAddNewLinkedUrlInput(true);
              }}
            >
              <FormattedMessage id="dashboard.account.details.linkedUrl.add" />
            </TextButton>
          </Box>
        </LinkedUrlList>
      </LinkedUrlBox>
    </Box>
  );
};

export default injectIntl(DomainInputWithAssociatedUrls);
