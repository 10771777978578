import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import styled, { css } from 'pwm-components/styled';
import ThemeProvider from 'pwm-components/ThemeProvider';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';
import TextButton from 'pwm-components/components/TextButton';

import { deleteExtension } from '../../dashboard/DashboardActions';

import { showGetProFree } from '../../user/selectors';

import AuthPageInformationsCreate from './AuthPageInformationsCreate';
import AuthPageInformationsFtu from './AuthPageInformationsFtu';
import AuthPageInformationsLogin from './AuthPageInformationsLogin';
import AuthPageInformationsRegister from './AuthPageInformationsRegister';

import { languageCodes } from '../../locales';

import giftBox from '../../img/gift_box_red.png';
import giftBox2x from '../../img/gift_box_red@2x.png';

import pwmIcon from '../../img/icon-logo-big.png';
import pwmIcon2x from '../../img/icon-logo-big@2x.png';

import aviraIcon from '../../img/avira-logo.png';
import aviraIcon2x from '../../img/avira-logo@2x.png';

import chip from '../../img/icon-chip.png';
import chip2x from '../../img/icon-chip@2x.png';

import uninstallIcon from '../../img/service-cleaning-off@1x.png';
import uninstallIcon2x from '../../img/service-cleaning-off@2x.png';

import backgroundImage from '../../img/giant_lock_dark_scheme@1x.png';

const bottomBanner = (
  <div
    className="u-fx u-fx-ai-c u-pos-a u-pl-xxs u-pt-s"
    style={{ top: 560, left: 450 }}
  >
    <img
      className="u-d-ib u-mr-xl"
      src={giftBox}
      srcSet={`${giftBox} 1x, ${giftBox2x} 2x`}
      alt="Gift box"
    />
    <div
      className="u-mt-l u-ml-xs u-color-silver u-pt-m u-pb-m"
      style={{
        minWidth: 300,
        border: '1px solid #586775',
        borderLeft: 'transparent',
        borderRadius: 3,
      }}
    >
      <p className="u-txt-strong u-color-gainsboro u-mb-xxs">
        <FormattedMessage
          id="dashboard.milestones.getProFree.header"
        />
      </p>
      <p>
        <FormattedMessage
          id="dashboard.milestones.getProFree.message"
        />
      </p>
    </div>
  </div>
);

const HeaderTabContainer = styled((({ visibility, ...props }) => <Box {...props} />))`
  height: 80px;
  display: flex;

  ${({ visibility }) => css`
    visibility: ${visibility ? 'visible' : 'hidden'};

    @media screen and (max-width: 480px) {
      display: ${visibility ? 'flex' : 'none'};
    }
  `}

  @media screen and (max-width: 480px) {
  height: 40px;
  }
`;

const HeaderTab = styled(Box)`
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50%;
  background-color: ${({ active, theme }) => (active ? 'transparent' : theme.colors.darkslategrey)};
  cursor: pointer;

  & ${Text} {
    color: ${({ active, theme }) => (active ? theme.colors.martinique : theme.colors.white)};
  }
`;

const Container = styled(Box)`
  background-position: left 144px bottom;
  background-size: auto 70%;
  background-repeat: no-repeat;
  background-color: #161F25;
  overflow: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const SectionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ContainerBox = styled(Box)`
  margin: 0 80px;

  @media screen and (max-width: 500px) {
    margin: 0 16px;
  }
`;

const LeftSection = styled('section')`
  padding: 40px 64px 48px 64px;
  width: 420px;
  min-width: 420px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding: 24px 48px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  @media screen and (max-width: 480px) {
    display: block;
    padding: 8px 12px;
    min-width: 0px;
  }
`;

const RightSection = styled('section')`
  padding-top: 64px;
  margin: 48px 80px;
  min-width: 420px;
  width: 420px;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  flex: 0 0 auto;

  @media screen and (max-width: 1024px) {
    padding-top: 0px;
    margin: 48px auto;
    min-width: 496px;
    padding-left: 48px;
    padding-right: 48px;
  }

  @media screen and (max-width: 480px) {
    padding: 8px 12px;
    min-width: 0px;
    width: 100%;
  }
`;

const MiddleSection = styled('div')`
  min-width: 496px;
  flex: 0 0 480px;
  z-index: 20;

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    max-width: 496px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    min-width: 0px;
  }
`;

const InformationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 610px;

  @media screen and (max-width: 1024px) {
    min-height: 0px;
  }
`;

const PwmIconContainer = styled('div')`
  margin-bottom: 16px;

  @media screen and (max-width: 1024px) {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    align-item: center;
    justify-content: center;
    margin-right: 0px;
    margin-top: 12px;
    margin-bottom: 0px;
  }
`;

const WelcomeTextContainer = styled('div')`
  ${Paragraph} {
    margin-top: 8px;
    margin-right: 8px;
  }
  ${Paragraph}:last-child {
    margin-top: 0px;
    margin-right: 0px;
  }
  ${Text} { }

  @media screen and (max-width: 1024px) {
    display: inline-block;
    ${Paragraph} {
      margin-top: 0px;
      display: inline-block;
      margin-right: 8px;
    }
    ${Paragraph}:last-child {
      margin-right: 0px;
    }
    ${Text} {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 480px) {
    display: flex;
    align-item: center;
    justify-content: center;
    ${Paragraph} {
      margin-top: 0px;
      margin-right: 3px;
      font-size: 16px;
      font-weight: bold;
    }
    ${Paragraph}:last-child {
      margin-right: 0px;
      font-size: 16px;
    }
    ${Text} {
      display: none;
      margin-right: 0px;
    }
  }
`;

const MiddleSectionWrapper = styled(Box)`
  height: 720px;

  @media screen and (max-width: 480px) {
    height: auto;
    padding-bottom: 16px;
  }
`;

const FooterSection = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 24px 48px 48px 64px;
  line-height: 24px;
  flex: 1;
  flex-shrink: 0;

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
`;


const AuthPageWrapper = ({
  showGetProFreeBanner,
  Page,
  showHeaderMenu,
  onDeleteExtension,
  location,
  showUninstall,
  language,
  history,
  registerDropOut,
  loginDropOut,
  showInfoSection = true,
}) => (
  <Container
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    <SectionContainer>
      <LeftSection>
        <PwmIconContainer>
          <img
            src={pwmIcon}
            srcSet={`${pwmIcon} 1x, ${pwmIcon2x} 2x`}
            alt="Avira Password Manager"
          />
        </PwmIconContainer>
        <WelcomeTextContainer>
          <Paragraph
            variant="light"
          >
            <FormattedMessage
              id="dashboard.ftu.D.welcome_msg1"
              defaultMessage="Welcome to"
            />
          </Paragraph>
          <Paragraph
            variant="light"
            size="h1"
          >
            <FormattedMessage
              id="dashboard.authPage.welcome1"
              defaultMessage="Avira"
            />
          </Paragraph>
          <Paragraph
            variant="light"
            size="h1"
            noWrap
          >
            <FormattedMessage
              id="dashboard.authPage.welcome2"
              defaultMessage="Password Manager!"
            />
          </Paragraph>
        </WelcomeTextContainer>
      </LeftSection>

      <MiddleSection>
        <MiddleSectionWrapper
          textAlign="left"
          position="relative"
          pt="0"
          bgOverride="whitesmoke"
          display="flex"
          flexDirection="column"
        >
          <HeaderTabContainer
            visibility={showHeaderMenu}
          >
            <HeaderTab
              id="a-register-tab"
              active={location.pathname === '/register' || location.pathname === '/ftu'}
              onClick={() => {
                if (location.pathname !== '/register') {
                  history.push('/register?dashboardContext=login');
                  loginDropOut();
                }
              }}
            >
              <strong>
                <Text>
                  <FormattedMessage
                    id="registerForFree.header"
                    defaultMessage="Register for free"
                  />
                </Text>
              </strong>
            </HeaderTab>
            <HeaderTab
              id="a-login-tab"
              active={location.pathname === '/login'}
              onClick={() => {
                if (location.pathname !== '/login') {
                  history.push('/login?dashboardContext=register');
                  registerDropOut();
                }
              }}
            >
              <strong>
                <Text>
                  <FormattedMessage
                    id="register.loginAction"
                    defaultMessage="Log in"
                  />
                </Text>
              </strong>
            </HeaderTab>
          </HeaderTabContainer>
          <ContainerBox
            style={{ flexGrow: 1 }}
          >
            <ThemeProvider mode="light">
              {typeof Page === 'function' ? Page() : Page}
            </ThemeProvider>
          </ContainerBox>
          {showGetProFreeBanner ? bottomBanner : null}
        </MiddleSectionWrapper>
      </MiddleSection>

      {
        showInfoSection && (
        <RightSection>
          <InformationContainer>
            <Route
              path="/ftu"
              render={() => (
                <AuthPageInformationsFtu
                  onDeleteExtension={onDeleteExtension}
                  showUninstall={showUninstall}
                />
              )}
            />
            <Route
              path="/register"
              render={() => (
                <AuthPageInformationsRegister />)}
            />
            <Route
              path="/login"
              render={() => (
                <AuthPageInformationsLogin />)}
            />
            <Route
              path="/create-master-password"
              render={() => (
                <AuthPageInformationsCreate />)}
            />
            {
              language === 'de' && (
                <Box
                  mt="auto"
                >
                  <img
                    src={chip}
                    srcSet={`${chip} 1x, ${chip2x} 2x`}
                    alt="Chip"
                  />
                </Box>
              )
            }
          </InformationContainer>
          <Route
            path="/ftu"
            render={() => (
              <Box
                mt="auto"
              >
                <TextButton
                  className="u-fx u-fx-ai-c u-cursor-p u-outline-none"
                  onClick={onDeleteExtension}
                >
                  <img
                    src={uninstallIcon}
                    srcSet={`${uninstallIcon} 1x, ${uninstallIcon2x} 2x`}
                    alt="Avira"
                  />
                  <Box ml="s">
                    <Text
                      variant="link"
                    >
                      <FormattedMessage
                        id="product.uninstallAviraPasswordManager"
                        defaultMessage="Uninstall Avira Password Manager"
                      />
                    </Text>
                  </Box>
                </TextButton>
              </Box>
            )}
          />
        </RightSection>
        )
    }
    </SectionContainer>

    <FooterSection>
      <Box
        display="flex"
        mt="auto"
        alignItems="flex-end"
      >
        <Box>
          <div>
            <img
              src={aviraIcon}
              srcSet={`${aviraIcon} 1x, ${aviraIcon2x} 2x`}
              alt="Avira"
            />
          </div>
          <Text variant="light">
            <FormattedMessage
              id="product.poweredByAvira"
              defaultMessage="powered by Avira"
            />
          </Text>
        </Box>
        <Box
          borderColor="gray"
          borderRight
          pl="s"
          pr="s"
        >
          <a href={`https://www.avira.com/${languageCodes[language]}/general-imprint`}>
            <FormattedMessage
              id="dashboard.authPage.Imprint"
              defaultMessage="Imprint"
            />
          </a>
        </Box>
        <Box
          pl="s"
        >
          <a href={`https://www.avira.com/${languageCodes[language]}/general-privacy`}>
            <FormattedMessage
              id="dashboard.authPage.Privacy"
              defaultMessage="Privacy"
            />
          </a>
        </Box>
      </Box>
    </FooterSection>
  </Container>
);

AuthPageWrapper.propTypes = {
  showGetProFreeBanner: PropTypes.bool.isRequired,
  Page: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  language: PropTypes.string,
  showUninstall: PropTypes.bool,
  showHeaderMenu: PropTypes.bool,
  location: PropTypes.object,
  onDeleteExtension: PropTypes.func,
  history: PropTypes.object,
  registerDropOut: PropTypes.func,
  loginDropOut: PropTypes.func,
  showInfoSection: PropTypes.bool,
};

AuthPageWrapper.defaultProps = {
  language: 'en',
  showUninstall: false,
  showHeaderMenu: false,
  location: {
    pathname: '',
  },
  onDeleteExtension: () => { },
  history: {
    push: () => { },
  },
  registerDropOut: () => { },
  loginDropOut: () => { },
  showInfoSection: true,
};

const mapStateToProps = state => ({
  showGetProFreeBanner: showGetProFree(state),
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({ onDeleteExtension: deleteExtension }, dispatch)
);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthPageWrapper));
