import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  array, func, number, string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import GetStartedTile from './GetStartedTile';
import ProgressIndicator from './ProgressIndicator';

import { TrackingActions, MixpanelEvents } from '../../tracking';
import PageTitle from '../../componentLib/PageTitle';

const {
  MP_GETSTARTED_SHOWN,
} = MixpanelEvents;

const { trackEvent } = TrackingActions;

const TilesContainer = styled('div')`
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-left: -2px;

  & > a {
    flex: 1 300px;
    margin: 2px;
  }

  @media screen and (max-width: 1024px) {
    /* grid-template-columns: repeat(2, 1fr); */
  }
`;

const getTileStatus = (started, completed) => {
  if (!started) return 'Not started';

  return completed ? 'Completed' : 'In progress';
};

const GetStartedOverviewPage = ({
  browser,
  points,
  maxPoints,
  tiles,
  tileUrlPrefix,
  trackGetStartedShown,
}) => {
  useEffect(() => {
    trackGetStartedShown(MP_GETSTARTED_SHOWN, {
      maxPoints,
      pointsEarned: points,
      ...(
        tiles.reduce((acc, { tileId, started, completed }) => (
          {
            ...acc,
            [`tile_${tileId}Status`]: getTileStatus(started, completed),
          }
        ), {})
      ),
    });
  }, [trackGetStartedShown, maxPoints, points, tiles]);

  const showProgress = Boolean(points);
  let subheadingId = 'dashboard.getStarted.overview.subheading.notStarted';

  if (points) {
    subheadingId = points < maxPoints
      ? 'dashboard.getStarted.overview.subheading.inProgress'
      : 'dashboard.getStarted.overview.subheading.completed';
  }

  return (
    <Box py="xxxl">
      <PageTitle
        renderAfter={() => (showProgress
          ? (
            <ProgressIndicator
              points={points}
              maxPoints={maxPoints}
            />
          )
          : null
        )}
      >
        <FormattedMessage
          id="dashboard.getStarted.overview.header"
          defaultMessage="Welcome to Avira Password Manager!"
        />
      </PageTitle>

      <Box
        width="80%"
        my="l"
        height="50px"
        minWidth="700px"
      >
        <Paragraph size="large">
          <FormattedMessage
            id={subheadingId}
          />
        </Paragraph>
      </Box>

      <TilesContainer>
        {
          tiles.map(
            tile => (
              <Link to={`${tileUrlPrefix}/${tile.link}`} key={tile.tileId}>
                <GetStartedTile
                  {...tile}
                  browser={browser}
                  showProgress={showProgress}
                />
              </Link>
            ),
          )
        }
      </TilesContainer>
    </Box>
  );
};

GetStartedOverviewPage.propTypes = {
  browser: string.isRequired,
  points: number.isRequired,
  maxPoints: number.isRequired,
  tiles: array.isRequired,
  tileUrlPrefix: string.isRequired,
  trackGetStartedShown: func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  trackGetStartedShown: trackEvent,
}, dispatch);

export default connect(null, mapDispatchToProps)(GetStartedOverviewPage);
