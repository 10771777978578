import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

export const FILTER_ALL = 'all';
export const FILTER_FAVORITES_ACCOUNT = 'is_favorite';
export const FILTER_FAVORITES_DEFAULT = 'favorite';
export const SORT_TYPE = {
  DESC: 'dsc',
  ASC: 'asc',
};

/* LIST VIEW HELPERS */
export const filterByQuery = (
  data: any,
  query: string,
  queryOn: ((data: any, query: string) => boolean) | string,
): boolean => {
  const normalizedQuery = query.trim().toLowerCase();

  if (typeof queryOn === 'function') {
    return !normalizedQuery || queryOn(data, normalizedQuery);
  }
  return !normalizedQuery
    || (queryOn.length > 0 && queryOn.toLowerCase().indexOf(normalizedQuery) >= 0);
};

const shouldIgnoreFilter = (
  data: { id: string; ignoreWarnings: boolean },
  ignoreFilter: { [key: string]: boolean },
): boolean => !!ignoreFilter[data.id];

export const filterHelper = (
  dataList: any,
  query = '',
  filterFn = (_data: any) => true,
  ignoreFilter: { [key: string]: boolean } = {},
  queryOn = '',
// eslint-disable-next-line max-params
) => (id: string): boolean => {
  const data = dataList[id];

  return (
    filterByQuery(data, query, queryOn)
      && (shouldIgnoreFilter(data, ignoreFilter) || filterFn(data))
  );
};

export const sortByHelper = (data: any, sortOption: any) => (idA: any, idB: any) => {
  let k1val;
  let k2val;

  if (sortOption.comparator && typeof sortOption.comparator === 'function') {
    return sortOption.comparator(idA, idB, data);
  }

  if (typeof sortOption.sortKey === 'function') {
    k1val = (sortOption.sortKey(data[idA]));
    k2val = (sortOption.sortKey(data[idB]));
  } else {
    k1val = (data[idA][sortOption.sortKey]) || '';
    k2val = (data[idB][sortOption.sortKey]) || '';
  }

  if (sortOption.sortOrder === SORT_TYPE.DESC) {
    return k2val.localeCompare(k1val);
  }

  return k1val.localeCompare(k2val);
};

/* DETAILS VIEW HELPERS */
export function mergeDetails(nextData: any, currentData: any, changedKeys: any): any {
  const updatedChangedKeys = {};
  // incase change was triggered by a different browser
  // and the user had also made changes on the current browser
  const updatedData = {
    ...currentData,
    ...omit(nextData, changedKeys),
  };
  // check if the updated entity and the entity from nextProps have different keys
  // if yes then changes keys should be updated
  changedKeys.forEach((key: any) => {
    if (!isEqual(updatedData[key], nextData[key])) {
      (updatedChangedKeys as any)[key] = true;
    }
  });

  return { updatedData, updatedChangedKeys };
}

// eslint-disable-next-line max-params
export function handleDetailsChange(
  key: any,
  value: any,
  initialData: any,
  changedKeysObj: any,
): any {
  const newChangeObj = { [key]: value };
  // check for change in data
  const changedKeys = { ...changedKeysObj };
  // using isEqual in case of tags it would comapare the content of the array instead of reference.
  if (isEqual(initialData[key], newChangeObj[key])) {
    delete changedKeys[key];
  } else {
    changedKeys[key] = true;
  }

  return { newChangeObj, changedKeys };
}
