/* eslint no-param-reassign: 0 */

import * as Sentry from '@sentry/browser';

import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { State as AccountsState } from '@avira-pwm/redux/accounts';

import { validatePasswordStrength } from '../lib/AuthenticationValidator';
import { securityScoreMap } from '../lib/SecurityStatusHelper';
import { getAccountSecurityStatus } from './accountSelector';
import { getSecurityStatusTrackingData } from './selectors';

import {
  SECURITY_STATUS_PREFERENCES_SCROLLPOSITION,
  AUC_ADD_RESULTS,
} from './SecurityStatusActionTypes';
import {
  PREFS_DISMISS_BREACH_CHECK_BANNER,
  PREFS_DISMISS_OTHER_BREACHES_BANNER,
} from '../preferences/PreferencesActionTypes';
import { updateAccount, updateAccounts } from '../accounts/AccountActions';

import { TrackingActions, MixpanelEvents } from '../tracking';
import { isValidURL } from '../lib/DomainNameHelper';


enum PasswordScore {
  veryWeak = 0,
  weak,
  medium,
  strong,
  veryStrong,
}

const { trackEvent } = TrackingActions;
const {
  MP_SECURITYSTATUS_ACTION_BUTTON_CLICKED,
  MP_SECURITYSTATUS_IGNORED,
  MP_SECURITYSTATUS_UNIGNORED,
  MP_SECURITYSTATUS_INITIALIZE,
  MP_SECURITYSTATUS_DISPLAY,
} = MixpanelEvents;


type State = {
  accounts: AccountsState;
  auc: any;
  preferences: any;
}

type ExtraArguments = {
  aucLib: any;
  syncInstance: any;
}

type SecurityStatusThunkAction<R> = ThunkAction<R, State, ExtraArguments, AnyAction>

export const initAUC = ():
SecurityStatusThunkAction<void> => (dispatch, getState, { aucLib }) => aucLib.initialize();

export const loadAUC = ():
SecurityStatusThunkAction<void> => async (dispatch, getState, { aucLib }) => {
  const { accounts, auc } = getState();

  const domains = Object.values(accounts)
    .map(account => account.domain)
    .filter(x => x) as string[];

  const uniqueDomains = [...new Set(domains)];
  const urls = uniqueDomains
    .map(domain => `https://${domain}/`)
    .filter(url => auc[url] == null && isValidURL(url));

  if (urls.length === 0) return;

  try {
    dispatch({
      type: AUC_ADD_RESULTS,
      value: await aucLib.classify(urls),
    });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
};

export const trackSecurityStatusIgnored = (securityStatus: any):
SecurityStatusThunkAction<void> => async (dispatch) => {
  dispatch(trackEvent(MP_SECURITYSTATUS_IGNORED, { ignoredWarnings: securityStatus }));
};

export const trackSecurityStatusUnignored = ():
SecurityStatusThunkAction<void> => trackEvent(MP_SECURITYSTATUS_UNIGNORED);

export const setIgnoreWarning = (account: any, value: boolean):
// eslint-disable-next-line complexity
SecurityStatusThunkAction<void> => async (dispatch) => {
  if (value) {
    const ignoreAviraBreachedWebsite = [...(account.ignoreAviraBreachedWebsite || [])];
    if (account.aviraBreachedWebsiteId) {
      ignoreAviraBreachedWebsite.push(account.aviraBreachedWebsiteId);
    }
    const ignoreHibpBreachedWebsite = [...(account.ignoreHibpBreachedWebsite || [])];
    if (account.hibpBreachedWebsiteId) {
      ignoreHibpBreachedWebsite.push(account.hibpBreachedWebsiteId);
    }
    const ignoreHibpBreachedAccount = [...(account.ignoreHibpBreachedAccount) || []];
    if (account.hibpBreachedAccountId) {
      ignoreHibpBreachedAccount.push(account.hibpBreachedAccountId);
    }
    const ignoreDependentWarnings = [...(account.ignoreDependentWarnings) || []];
    if (account.dependentBreachWarning) {
      ignoreDependentWarnings.push(account.dependentBreachId);
    }

    const ignoredWarnings = {
      ignoreReused: account.reused,
      ignorePasswordStrength:
        securityScoreMap.password[account.passwordScore as PasswordScore] === 0
          ? null
          : account.passwordScore,
      ignoreAviraBreachedWebsite,
      ignoreHibpBreachedWebsite,
      ignoreHibpBreachedAccount,
      ignoreHibpBreachedPassword: account.password,
      ignoreInsecureProtocol: account.insecureProtocol,
      ignoreDependentWarnings,
      ignoreAUC: account.auc,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    dispatch(updateAccount(account.id, ignoredWarnings));
    dispatch(trackSecurityStatusIgnored(ignoredWarnings));
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    dispatch(updateAccount(account.id, {
      ignoreReused: false,
      ignorePasswordStrength: null,
      ignoreAviraBreachedWebsite: [],
      ignoreHibpBreachedWebsite: [],
      ignoreHibpBreachedAccount: [],
      ignoreHibpBreachedPassword: null,
      ignoreInsecureProtocol: false,
      ignoreDependentWarnings: [],
      ignoreAUC: false,
    }));
    dispatch(trackSecurityStatusUnignored());
  }
};

export const getUpdatedSecurityStatusIgnoredWarnings = (account: any): any => {
  const ignoredWarnings = {} as any;

  if (account.password && account.ignorePasswordStrength != null) {
    const newPasswordScore = validatePasswordStrength(account.password)!.score as PasswordScore;
    if (securityScoreMap.password[newPasswordScore] === 0
        && newPasswordScore !== account.ignorePasswordStrength) {
      ignoredWarnings.ignorePasswordStrength = null;
    }
  }

  if (account.ignoreHibpBreachedPassword && account.password
    && account.password !== account.ignoreHibpBreachedPassword) {
    ignoredWarnings.ignoreHibpBreachedPassword = null;
  }

  return ignoredWarnings;
};

export const updateIgnoreWarnings = (accounts: any):
SecurityStatusThunkAction<void> => async (dispatch) => {
  const accountsToUpdate: { id: string; data: any }[] = [];
  Object.keys(accounts).forEach((accountId) => {
    const updatedIgnoredWarnings = getUpdatedSecurityStatusIgnoredWarnings(accounts[accountId]);
    if (Object.keys(updatedIgnoredWarnings).length > 0) {
      accountsToUpdate.push({ id: accountId, data: updatedIgnoredWarnings });
    }
  });
  if (accountsToUpdate.length > 0) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    dispatch(updateAccounts(accountsToUpdate));
  }
};

export const dismissBreachCheckBanner = (): any => ({ type: PREFS_DISMISS_BREACH_CHECK_BANNER });

export const dismissOtherBreachesBanner = (): any => ({
  type: PREFS_DISMISS_OTHER_BREACHES_BANNER,
});

export const trackActionButtonClick = (actionType: string):
SecurityStatusThunkAction<void> => (dispatch) => {
  dispatch(trackEvent(MP_SECURITYSTATUS_ACTION_BUTTON_CLICKED, { type: actionType }));
};

export const setShowcaseAccountId = (): SecurityStatusThunkAction<void> => (dispatch, getState) => {
  const accountsSecurityStatus: any[] = Object.values(getAccountSecurityStatus(getState()));

  const demoAccounts = accountsSecurityStatus.filter((account: any) => !!account.isDemoAccount);

  const MAX_DEMOACCOUTNS = 3;
  const total = accountsSecurityStatus.length;
  const current = demoAccounts.length;
  const needed = Math.min(MAX_DEMOACCOUTNS, total) - current;

  if (needed > 0) {
    const nonDemoAccounts = accountsSecurityStatus.filter(
      acc => !acc.isDemoAccount && !acc.fakeAccount,
    );
    nonDemoAccounts.sort((ac1, ac2) => ac1.securityScore - ac2.securityScore);
    const newDemoAccounts = nonDemoAccounts.slice(0, needed);

    const updates = newDemoAccounts.map(({ id }) => ({ id, data: { isDemoAccount: true } }));
    dispatch(updateAccounts(updates));
  }
};

export const setScrollPosition = (scrollPosition: any): any => (
  { type: SECURITY_STATUS_PREFERENCES_SCROLLPOSITION, value: scrollPosition }
);

export const trackSecurityStatusInitialize = ():
SecurityStatusThunkAction<void> => (dispatch, getState) => {
  const trackingObject = getSecurityStatusTrackingData(getState());
  dispatch(trackEvent(MP_SECURITYSTATUS_INITIALIZE, trackingObject));
};

export const trackSecurityStatusDisplay = ():
SecurityStatusThunkAction<void> => (dispatch, getState) => {
  const trackingObject = getSecurityStatusTrackingData(getState());
  dispatch(trackEvent(MP_SECURITYSTATUS_DISPLAY, trackingObject));
};
