import tldjs from 'tldjs';
import ipaddr from 'ipaddr.js';

const DISALLOWED_PROTOCOLS = ['file:'];

export const constructValidUrl = (host) => {
  try {
    return new URL(host).toString();
  } catch (e) {
    try {
      if (!(/\w+:\/\//).test(host)) {
        return new URL(`http://${host}`).toString();
      }

      return null;
    } catch (er) {
      return null;
    }
  }
};

export const isValidURL = host => (!!constructValidUrl(host));

const getUrlObj = (url) => {
  const urlObj = new URL(url);

  const { hostname, origin, protocol } = urlObj;
  if (!origin || origin === 'null' || !hostname) {
    return null;
  }

  if (DISALLOWED_PROTOCOLS.includes(protocol)) {
    return null;
  }

  return urlObj;
};

// eslint-disable-next-line max-statements, complexity
export const getDomain = (url) => {
  const validUrl = constructValidUrl(url || '');
  if (!validUrl) {
    return null;
  }
  try {
    const urlObj = getUrlObj(validUrl);
    if (!urlObj) return null;

    if (tldjs.tldExists(validUrl)) {
      const domain = tldjs.getDomain(validUrl);
      if (domain) {
        return domain;
      }
    }

    const { hostname } = urlObj;

    if (!hostname) {
      return validUrl;
    }

    const ipv6match = hostname.match(/^\[(.*?)\]$/);

    if (ipv6match && ipv6match[1]) {
      const tempHostname = ipv6match[1];

      if (ipaddr.isValid(tempHostname)) {
        const ip = ipaddr.parse(tempHostname);
        return ip.toString();
      }
    }

    return hostname;
  } catch (e) {
    return null;
  }
};

export const getProtocol = (url) => {
  const validUrl = constructValidUrl(url || '');
  if (!validUrl) {
    return null;
  }
  try {
    const urlObj = new URL(validUrl);
    return urlObj.protocol;
  } catch (e) {
    return null;
  }
};

export const getSubdomain = (url) => {
  const validUrl = constructValidUrl(url || '');

  if (!validUrl) {
    return null;
  }

  try {
    const urlObj = getUrlObj(validUrl);
    if (!urlObj) return null;
    return urlObj.hostname;
  } catch {
    return null;
  }
};
