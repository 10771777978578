/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import * as Sentry from '@sentry/browser';
import { sha256, generateKey } from '@avira-pwm/crypto-tools';
import { pwnedPassword } from 'hibp';
import ms from 'ms';

import debug from '../debug';

import {
  OE_AUTH_STORE,
  OE_LOGOUT,
  OE_UPDATE_TOKEN,
  OE_USER_DATA_STORE,
  OE_SET_GDPR,
  OE_SET_PAYMENT_URL,
  OE_SET_UNREGISTERED_TOKENS,
} from './OEActionTypes';

import * as ErrorHelper from '../lib/ErrorHelper';

import { sendSync } from '../authentication/AuthenticationActions';
import { clearKey, clearAuthToken } from '../user/UserActions';
import { activateUnregisteredMode, deactivateUnregisteredMode } from '../dashboard/DashboardActions';

import { getEmailErrorCode, getPasswordErrorCode } from '../lib/AuthenticationHelper';
import { validateEmail, validatePassword } from '../lib/AuthenticationValidator';
import * as SpotlightAPI from '../lib/SpotlightAPI';
import { TrackingActions, MixpanelEvents, MixpanelErrorDictionary } from '../tracking';
import mixpanel from '../tracking/mixpanel';
import { getBrowser, getOS } from '../lib/UserAgent';
import stringFormatter from '../lib/StringFormatter';
import envConfig from '../config';
import { getRelevantOeTokens } from './selector';

const log = debug.extend('OEActions');

const { identify, trackEvent } = TrackingActions;
const {
  MP_OE_LOGIN_SHOWN,
  MP_OE_LOGIN_INITIATED,
  MP_OE_LOGIN,
  MP_OE_LOGIN_FAILED,
  MP_OE_LOGIN_DROP_OUT,
  MP_OE_REGISTER_SHOWN,
  MP_OE_REGISTER,
  MP_OE_REGISTER_FAILED,
  MP_OE_REGISTER_DROP_OUT,
  MP_OE_LOGIN_RESEND_OTP,
  MP_OPEN_ACCOUNT,
} = MixpanelEvents;

const getSecondsFromTimestamp = timestamp => Math.floor((Date.now() - timestamp) / 1000);
const skipGdprEmailRegex = new RegExp(/^CI_.*@pwm\.avira\.com/i);

const fromOe = ({
  access_token,
  refresh_token,
  expires_in,
  aviraToken,
}) => ({
  token: access_token,
  refreshToken: refresh_token,
  trustedToken: aviraToken,
  expiresAt: (expires_in * 1000) + Date.now(),
  expiresIn: expires_in * 1000,
});

export const oeStoreToken = ({
  email,
  id,
  token,
  expiresAt,
  expiresIn,
  refreshToken,
  trustedToken,
  socialMediaUniqueId,
}) => ({
  type: OE_AUTH_STORE,
  email,
  id,
  token,
  expiresAt,
  expiresIn,
  refreshToken,
  trustedToken,
  socialMediaUniqueId,
});
export const oeClearToken = () => ({ type: OE_LOGOUT });
export const storeOEUserData = data => ({ type: OE_USER_DATA_STORE, data });
export const setGdpr = ({ consent, confirm }) => ({ type: OE_SET_GDPR, consent, confirm });
export const logoutOEUser = () => (dispatch) => {
  dispatch(oeClearToken());
  dispatch(identify(null));
};

// eslint-disable-next-line complexity
export const refreshOE = () => async (
  dispatch, getState, { oeRequest, dashboardMessenger },
) => {
  const state = getState();
  const {
    dashboard: { isUnregisteredMode },
    tracking: { localSalt },
  } = state;
  const { token, refreshToken, expiresAt } = getRelevantOeTokens(state);

  Sentry.addBreadcrumb({
    message: 'refreshOE start',
    data: {
      hasRefreshToken: !!refreshToken,
      isExpired: !!(refreshToken && expiresAt < Date.now()),
    },
  });
  const isExpired = expiresAt < Date.now();

  log(`refreshOE start refreshToken: ${refreshToken} expired: ${isExpired} isUnregisteredMode: ${isUnregisteredMode}`);

  if (refreshToken && isExpired) {
    if (isUnregisteredMode) {
      dashboardMessenger.send('dashboard:extension:refreshTempOeToken');
    } else {
      log('refershOE start refresh');
      const oeData = fromOe(await oeRequest.request(oeRequest.refresh(refreshToken)));
      Sentry.addBreadcrumb({ message: 'refreshOE request success' });
      dispatch(trackEvent('OETokenRefresh', {
        tokenHash: (token && localSalt)
          ? sha256(token + localSalt)
          : undefined,
        refreshTokenHash: (refreshToken && localSalt)
          ? sha256(refreshToken + localSalt)
          : undefined,
        newTokenHash: (oeData.token && localSalt)
          ? sha256(oeData.token + localSalt)
          : undefined,
        newRefreshTokenHash: (oeData.refreshToken && localSalt)
          ? sha256(oeData.refreshToken + localSalt)
          : undefined,
      }));
      dispatch({
        type: OE_UPDATE_TOKEN,
        ...oeData,
      });
      dispatch(sendSync());
    }
  }

  Sentry.addBreadcrumb({ message: 'refreshOe done' });
  log('refreshOE done');
};

const trackOEEvent = (event, properties, mountTimestamp) => (dispatch) => {
  dispatch(trackEvent(event, {
    timeSpent: getSecondsFromTimestamp(mountTimestamp),
    type: 'email',
    ...properties,
  }));
};

const getTrustedBrowser = (otp, trustBrowser, trustedToken) => {
  const trustedBrowser = {
    os: getOS(),
    browser: getBrowser(),
  };

  if (otp || trustBrowser) {
    trustedBrowser.trusted = !!trustBrowser;
  } else if (trustedToken) {
    trustedBrowser.token = trustedToken;
  }
  return trustedBrowser;
};

const trackOEUserError = (eventName, e, type) => (
  (dispatch) => {
    const errorCode = ErrorHelper.getErrorCode(e);
    const cause = MixpanelErrorDictionary[errorCode];

    dispatch(trackEvent(eventName, {
      cause,
      cause_error: e.error,
      cause_description: e.error_description || JSON.stringify(e.errors),
      type,
    }));
  }
);

export const trackLoginOEUserError = (e, type) => (
  (dispatch) => {
    dispatch(trackOEUserError(MP_OE_LOGIN_FAILED, e, type));
  }
);

export const trackRegisterOEUserError = (e, type) => (
  (dispatch) => {
    dispatch(trackOEUserError(MP_OE_REGISTER_FAILED, e, type));
  }
);

// eslint-disable-next-line max-params
const handleOEUserError = (eventName, dispatch, e, type) => {
  const errorCode = ErrorHelper.getErrorCode(e);
  const errorDescription = ErrorHelper.getErrorDescription(errorCode);

  dispatch(trackOEUserError(eventName, e, type));

  throw errorDescription;
};

const handleLoginOEUserError = (dispatch, e, type) => {
  if (e.error === 'invalid_otp') {
    return { otpRequired: true, aviraPhone: e.aviraPhone };
  }
  return handleOEUserError(MP_OE_LOGIN_FAILED, dispatch, e, type);
};

const handleRegisterOEUserError = (dispatch, e, type) => (
  handleOEUserError(MP_OE_REGISTER_FAILED, dispatch, e, type)
);

const trackAndThrowErrorCode = (eventName, dispatch, errorCode) => {
  // Often the Initiated event and the Failed event are triggered at the same time
  // and most of the time the second request will finist slightly earlier than the second
  // breaking the funnel order, hence the setTimeout
  // Maybe this should be moved to the mixpanel library, or maybe by switching to the offical
  // mixpanel lib there shouldn't be a reason we can't use it on the dashboard
  setTimeout(() => {
    dispatch(trackEvent(eventName, {
      cause: MixpanelErrorDictionary[errorCode],
      cause_error: errorCode,
      type: 'email',
    }));
  }, 50);
  throw ErrorHelper.getErrorDescription(errorCode);
};

const trackAndThrowLoginErrorCode = (dispatch, errorCode) => (
  trackAndThrowErrorCode(MP_OE_LOGIN_FAILED, dispatch, errorCode)
);

const trackAndThrowRegisterErrorCode = (dispatch, errorCode) => (
  trackAndThrowErrorCode(MP_OE_REGISTER_FAILED, dispatch, errorCode)
);

export const setPaymentUrl = () => async (dispatch, getState) => {
  const { intl } = getState();
  const paymentUrl = stringFormatter(envConfig.getPWMProLink, intl ? intl.locale : 'de');

  try {
    dispatch({ type: OE_SET_PAYMENT_URL, paymentUrl });
  } catch (e) {
    dispatch({ type: OE_SET_PAYMENT_URL, paymentUrl: null });
    Sentry.captureException(e);
  }
};

export const openConnectDashboard = (
  section,
  context,
) => async (dispatch, getState, { oeService }) => {
  const { oe, intl } = getState();
  const LANGUAGE = intl.locale;
  try {
    const { dashboardUrl } = await oeService.getDashboardUrls(oe.token, section, LANGUAGE);
    window.open(dashboardUrl);
  } catch (e) {
    Sentry.captureException(e);
    const { myAviraHost } = envConfig.hostConfig;
    window.open(`${myAviraHost}${section}`);
  }
  dispatch(trackEvent(MP_OPEN_ACCOUNT, { context }));
};

export const openCancelRenewal = context => async (dispatch, getState) => {
  const { intl } = getState();

  const link = stringFormatter(envConfig.connect.cancellation, intl.locale);

  window.open(link);
  dispatch(trackEvent(MP_OPEN_ACCOUNT, { context }));
};


export const openContractCancelationRefund = context => (dispatch, getState) => {
  const { intl } = getState();

  const link = stringFormatter(envConfig.connect.support, intl.locale);

  window.open(link);
  dispatch(trackEvent(MP_OPEN_ACCOUNT, { context }));
};

export const loginOEUser = ({
  email,
  password,
  firstName,
  lastName,
  country,
  updateUserInfo = false,
  captchaKey,
  mountTimestamp,
  otp,
  trustBrowser,
// eslint-disable-next-line max-statements, complexity
}) => async (dispatch, getState, { oeRequest, dashboardMessenger, oeService }) => {
  dispatch(trackEvent(MP_OE_LOGIN_INITIATED));

  if (!validateEmail(email)) {
    return trackAndThrowLoginErrorCode(dispatch, getEmailErrorCode(email));
  }

  if (!password) {
    return trackAndThrowLoginErrorCode(dispatch, getPasswordErrorCode(password));
  }

  try {
    const { oe, preferences } = getState();
    const trustedToken = oe.trustedTokens[email];
    const trustedBrowser = getTrustedBrowser(otp, trustBrowser, trustedToken);
    const request = oeRequest.login(email, password, captchaKey, otp, trustedBrowser);
    const response = await oeRequest.request(request);
    const oeData = fromOe(response);

    if (updateUserInfo && firstName && lastName && country) {
      await oeService.updateUserData(oeData.token, {
        first_name: firstName,
        last_name: lastName,
        country,
      });
    }

    const userData = await oeService.getUserData(oeData.token);

    const oeInfo = { email, id: userData.data.id, ...oeData };

    if (
      userData.data.attributes.status_us_compliance_verified_date != null
      && userData.data.attributes.status_us_compliance_approved === false
    ) {
      throw new Error('Rejected by trade compliance validation');
    }

    dispatch(oeStoreToken(oeInfo));
    dispatch(setPaymentUrl());
    dispatch(sendSync());
    dispatch(trackOEEvent(MP_OE_LOGIN, { type: 'email' }, mountTimestamp));
    dashboardMessenger.send('dashboard:extension:sendAutolockPref', preferences.autolock);

    return oeInfo;
  } catch (e) {
    return handleLoginOEUserError(dispatch, e, 'email');
  }
};

export const validateUser = ({
  email,
  password,
}) => async (dispatch) => {
  if (!validateEmail(email)) {
    trackAndThrowRegisterErrorCode(dispatch, getEmailErrorCode(email));
    return;
  }

  if (!validatePassword(password)) {
    trackAndThrowRegisterErrorCode(dispatch, getPasswordErrorCode(password));
    return;
  }

  let numberOfBreaches = null;
  try {
    numberOfBreaches = await pwnedPassword(password);
  } catch (e) { /** HIBP calls might fail; we can ignore it */ }

  if (numberOfBreaches) {
    trackAndThrowRegisterErrorCode(dispatch, getPasswordErrorCode(password, true));
  }
};

export const registerOEUser = ({
  email,
  password,
  firstName,
  lastName,
  country,
  mountTimestamp,
  context,
}) => async (dispatch, getState, { oeRequest, oeService }) => {
  try {
    const data = await oeRequest.request(oeRequest.anonymous());
    const anonymousToken = fromOe(data).token;
    await oeRequest.request(
      oeRequest.register(anonymousToken, email, password, firstName, lastName, country),
    );
    const oeData = fromOe(await oeRequest.request(oeRequest.login(email, password)));
    const userData = await oeService.getUserData(oeData.token);

    const oeInfo = { email, id: userData.data.id, ...oeData };

    dispatch(oeStoreToken(oeInfo));
    dispatch(setPaymentUrl());
    dispatch(sendSync());
    dispatch(trackOEEvent(MP_OE_REGISTER, { type: 'email', context }, mountTimestamp));

    return oeInfo;
  } catch (e) {
    return handleRegisterOEUserError(dispatch, e, 'email');
  }
};

export const setAnonymousOEUser = oeData => async (dispatch) => {
  try {
    dispatch({
      type: OE_SET_UNREGISTERED_TOKENS,
      token: oeData.tempOeToken,
      refreshToken: oeData.tempOeRefreshToken,
      expiresAt: oeData.tempExpiresAt,
      expiresIn: oeData.tempExpiresIn,
    });
    dispatch(setPaymentUrl());
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const trackAuthShown = (currentForm, dashboardSource) => async (dispatch) => {
  if (dashboardSource) {
    mixpanel.register({
      DashboardSource: dashboardSource,
    });
  }
  const eventName = currentForm === 'login' ? MP_OE_LOGIN_SHOWN : MP_OE_REGISTER_SHOWN;
  dispatch(trackEvent(eventName));
};

export const authDropout = ({ currentForm, cause, mountTimestamp }) => (dispatch) => {
  let eventName;

  if (currentForm === 'login') {
    eventName = MP_OE_LOGIN_DROP_OUT;
  } else {
    eventName = MP_OE_REGISTER_DROP_OUT;
  }

  dispatch(trackEvent(eventName, {
    timeSpent: getSecondsFromTimestamp(mountTimestamp),
    cause,
  }));
};

export const getOEUserData = () => async (dispatch, getState, { oeService }) => {
  const { oe } = getState();
  await dispatch(refreshOE());
  const userData = await oeService.getUserData(oe.token);

  const gdpr = {
    confirm: !!userData.data.attributes.gdpr_confirm,
    consent: !!userData.data.attributes.gdpr_consent,
  };
  if (skipGdprEmailRegex.test(oe.email) && envConfig.environment !== 'production') {
    gdpr.confirm = true;
    gdpr.consent = true;
  }
  dispatch(setGdpr(gdpr));
  if (!oe.email || !oe.id) {
    dispatch(oeStoreToken({ ...oe, email: userData.data.attributes.email, id: userData.data.id }));
  }

  dispatch(storeOEUserData({ ...userData.data.attributes }));
  return {
    ...gdpr,
    userData: userData.data.attributes,
  };
};

export const trackInvalidOtp = () => async (dispatch) => {
  dispatch(trackEvent(MP_OE_LOGIN_FAILED, {
    cause: MixpanelErrorDictionary.invalid_otp,
  }));
};

export const trackResendOtp = () => async (dispatch) => {
  dispatch(trackEvent(MP_OE_LOGIN_RESEND_OTP));
};

export const resendVerificationEmail = () => async (dispatch, getState, { oeRequest }) => {
  const { oe } = getState();
  await oeRequest.resendVerificationEmail(oe.token);
};

export const registerLoginOEUserWithSocialMedia = ({
  accessToken,
  handle,
  otp,
  trustBrowser,
  socialMediaUniqueId,
  mountTimestamp,
  context,
  redirectURI,
  // eslint-disable-next-line max-statements
}) => async (dispatch, getState, { oeRequest, oeService, dashboardMessenger }) => {
  const now = Date.now();

  const { oe, preferences } = getState();
  const trustedToken = oe.trustedTokens[socialMediaUniqueId];
  const trustedBrowser = getTrustedBrowser(otp, trustBrowser, trustedToken);
  try {
    const oeResponse = await oeRequest.getPermanentTokenForSocialMediaToken(
      accessToken, handle, otp, trustedBrowser, redirectURI,
    );
    const oeData = fromOe(oeResponse);
    const userData = await oeService.getUserData(oeData.token);

    if (!oeData.email) {
      oeData.email = userData.data.attributes.email;
    }

    let isNewUser = false;

    if (userData.date_registered) {
      const registerDate = new Date(
        userData.data.date_added || userData.data.date_registered,
      ).getTime();
      isNewUser = (now - ms('10s')) < registerDate;
    }

    const oeInfo = { ...oeData, socialMediaUniqueId };

    dispatch(oeStoreToken(oeInfo));
    dispatch(setPaymentUrl());
    dispatch(sendSync());
    dispatch(trackOEEvent(
      isNewUser ? MP_OE_REGISTER : MP_OE_LOGIN,
      { type: handle, context },
      mountTimestamp,
    ));

    dashboardMessenger.send('dashboard:extension:sendAutolockPref', preferences.autolock);

    return oeInfo;
  } catch (e) {
    return handleLoginOEUserError(dispatch, e, handle);
  }
};

const authenticateSpotlightUser = userData => (
  async (dispatch) => {
    log('authenticateSpotlightUser start');
    const authData = await new Promise((resolve, reject) => {
      window.external.Authenticate('passm', (responseCode, data) => {
        if (responseCode === 200) {
          resolve(JSON.parse(data));
        } else {
          log('loginSpotlightUser Authenticate failed', responseCode, data);
          reject();
        }
      });
    });
    log('authenticateSpotlightUser authData:', authData);

    const gdpr = {
      confirm: !!userData.data.attributes.gdpr_confirm,
      consent: !!userData.data.attributes.gdpr_consent,
    };
    log('authenticateSpotlightUser gdpr:', gdpr);

    const oeData = fromOe(authData);
    const oeInfo = { ...oeData, email: userData.data.attributes.email, id: userData.data.id };
    await dispatch(oeStoreToken(oeInfo));
    await dispatch(storeOEUserData(userData.data.attributes));
    await dispatch(setGdpr(gdpr));
    dispatch(setPaymentUrl());
    log('authenticateSpotlightUser finished');
  }
);

// eslint-disable-next-line max-statements, complexity
export const loginSpotlightUser = () => async (dispatch, getState) => {
  // do not check for AcpGet or Authenticate as it is passed in by spotlight
  // and it is of type unkown therefore checking for the functions breaks the app
  const { oe } = getState();
  const emailLocal = oe.email;
  log('loginSpotlightUser start; email: %s', emailLocal);

  const userData = await new Promise((resolve, reject) => {
    window.external.AcpGet('backend', '/v2/me', (responseCode, data) => {
      log('loginSpotlightUser receive backend response %s; %O', responseCode, data);
      if (responseCode === 200) {
        resolve(JSON.parse(data));
      } else {
        log('loginSpotlightUser AcpGet failed', responseCode, data);
        reject();
      }
    });
  });

  log('loginSpotlightUser userData:', userData);

  const emailSpotlight = userData.data.attributes.email;
  const userEmail = userData.data.attributes.email;
  const oeUserId = userData.data.id;

  // incase oe email and userDataEmail are both null get userdata from spotlight again
  if (oe.email === userEmail && !!oe.token
    && oe.email != null && userEmail != null) {
    try {
      if (oe.id == null || oe.id !== oeUserId) {
        await dispatch(oeStoreToken({ ...oe, id: oeUserId }));
      }
      await dispatch(refreshOE());
      dispatch(setPaymentUrl());
    } catch (e) {
      log('loginSpotlightUser refreshOE failed');
      // incase refreshOE fails for any reason fallback to getting user details from spotlight again
      await dispatch(authenticateSpotlightUser(userData));
    }
  } else {
    log('loginSpotlightUser user missmatch local: %s, spotlight: %s', emailLocal, emailSpotlight);
    await dispatch(clearAuthToken());
    await dispatch(clearKey());
    // if user Email exists then get oe token. If not check for unregistered mode
    if (userEmail) {
      await dispatch(authenticateSpotlightUser(userData));
    } else {
      await dispatch(logoutOEUser());
    }
  }
};

export const showPlans = () => (_dispatch, getState) => {
  const { oeData: { paymentUrl } } = getState();
  if (envConfig.spotlight) {
    SpotlightAPI.showPlans();
  } else {
    window.open(paymentUrl);
  }
};

export const spotlightUserChanged = () => async (dispatch) => {
  await dispatch(clearAuthToken());
  await dispatch(clearKey());
  await dispatch(logoutOEUser());
  await dispatch(loginSpotlightUser());
};

export const setupUnregisteredMode = (key, userId) => (
  async (dispatch) => {
    const tempUserId = userId || generateKey();
    const tempKey = key || generateKey();
    const now = Date.now();

    const authData = await new Promise((resolve, reject) => {
      window.external.Authenticate('passm', (responseCode, data) => {
        if (responseCode === 200) {
          resolve(JSON.parse(data));
        } else {
          reject();
        }
      });
    });

    const tempExpiresIn = authData.expires_in * 1000;

    const tempUserData = {
      tempOeToken: authData.access_token,
      tempExpiresAt: now + tempExpiresIn,
      tempExpiresIn,
      tempOeRefreshToken: authData.refresh_token,
    };

    await dispatch(activateUnregisteredMode({ tempKey, tempUserId }));
    await dispatch(setAnonymousOEUser(tempUserData));
    dispatch(sendSync());
  }
);

export const deactivateSpotlightUnregisteredMode = () => (
  async (dispatch) => {
    await dispatch(deactivateUnregisteredMode());
    await dispatch(setAnonymousOEUser({
      tempOeToken: null,
      tempExpiresAt: null,
      tempExpiresIn: null,
      tempOeRefreshToken: null,
    }));
  }
);

export const loginWithSpotlightUser = data => (
  async (dispatch, getState, { oeService }) => {
    const oeData = fromOe(data);
    const userData = await oeService.getUserData(oeData.token);
    const gdpr = {
      confirm: !!userData.data.attributes.gdpr_confirm,
      consent: !!userData.data.attributes.gdpr_consent,
    };
    const oeInfo = { ...oeData, email: userData.data.attributes.email, id: userData.data.id };
    await dispatch(oeStoreToken(oeInfo));
    await dispatch(storeOEUserData(userData.data.attributes));
    await dispatch(setGdpr(gdpr));
    dispatch(setPaymentUrl());
  }
);
