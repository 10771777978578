import config from '../config';
import { getExtensionBrowserName, isSafari } from '../lib/UserAgent';
import AboutUs from './components/steps/AboutUs';
import ComponentsOfAviraPWM from './components/steps/ComponentsOfAviraPWM';
import HowDoesItWorkVideo from './components/steps/HowDoesItWorkVideo';
import HowDoesPwmWork from './components/steps/HowDoesPwmWork';
import HowSafeIsMyData from './components/steps/HowSafeIsMyData';
import WhereIsMyDataStored from './components/steps/WhereIsMyDataStored';
import HowToActivateAutofillMobile from './components/steps/HowToActivateAutofillMobile';
import HowToUseExtension from './components/steps/HowToUseExtension';
import InstallExtension from './components/steps/InstallExtension';
import LocateTheExtension from './components/steps/LocateTheExtension';
import AutosavePassword from './components/steps/AutosavePassword';
import AutofillLoginForms from './components/steps/AutofillLoginForms';
import NavigateToSite from './components/steps/NavigateToSite';
import OpenWebDashboard from './components/steps/OpenWebDashboard';
import WhyUsePwmOnMobile from './components/steps/WhyUsePwmOnMobile';
import MobileAppLinks from '../componentLib/MobileAppLinks';
import FreeVsProOffers from './components/steps/FreeVsProOffers';
import SynchronizeYourData from './components/steps/SynchronizeYourData';
import HowToImportPasswords from './components/steps/HowToImportPasswords';
import HowToDisableVideo from './components/steps/HowToDisableVideo';
import WhyDisableSavingPasswords from './components/steps/WhyDisableSavingPasswords';
import HowToDisableSavingPasswords from './components/steps/HowToDisableSavingPasswords';
import DisableSavingInstallExtension from './components/steps/DisableSavingInstallExtension';
import WhatMakesAPasswordSecure from './components/steps/WhatMakesAPasswordSecure';
import WhyLong from './components/steps/WhyLong';
import WhyRandom from './components/steps/WhyRandom';
import WhyUnique from './components/steps/WhyUnique';
import YourMasterPassword from './components/steps/YourMasterPassword';
import AboutSecurityQuestions from './components/steps/AboutSecurityQuestions';
import { isUpsellAllowed } from '../user/selectors';

// eslint-disable-next-line import/prefer-default-export
export const getStartedConfig = [
  {
    tileId: 'howDoesItWork',
    link: 'how-does-it-work',
    steps: [
      {
        stepId: 'howDoesItWorkVideo',
        component: HowDoesItWorkVideo,
        useCustomCompletion: true,
      },
      {
        stepId: 'howDoesAPWMWork',
        component: HowDoesPwmWork,
      },
      {
        stepId: 'componentsOfAviraPWM',
        component: ComponentsOfAviraPWM,
      },
      {
        stepId: 'whereIsMyDataStored',
        component: WhereIsMyDataStored,
      },
      {
        stepId: 'howSafeIsMyData',
        component: HowSafeIsMyData,
        isApplicable: ({ dashboard }) => !dashboard.isUnregisteredMode,
      },
      {
        stepId: 'freeVsProOffers',
        component: FreeVsProOffers,
        isApplicable: state => isUpsellAllowed(state) && !state.dashboard.isUnregisteredMode,
      },
      {
        stepId: 'aboutUs',
        component: AboutUs,
      },
    ],
  },
  {
    tileId: 'importanceOfASecurePassword',
    link: 'importance-of-a-secure-password',
    steps: [
      {
        stepId: 'whatMakesAPasswordSecure',
        component: WhatMakesAPasswordSecure,
      },
      {
        stepId: 'whyLong',
        component: WhyLong,
      },
      {
        stepId: 'whyRandom',
        component: WhyRandom,
      },
      {
        stepId: 'whyUnique',
        component: WhyUnique,
      },
      {
        stepId: 'yourMasterPassword',
        component: YourMasterPassword,
      },
      {
        stepId: 'aboutSecurityQuestions',
        component: AboutSecurityQuestions,
      },
    ],
  },
  {
    tileId: 'useBrowserExtension',
    link: 'use-the-browser-extension',
    steps: [
      {
        stepId: 'howToUseExtension',
        component: HowToUseExtension,
        useCustomCompletion: true,
      },
      {
        stepId: 'installExtension',
        component: InstallExtension,
        isAchieved: ({ milestones, extensionConnected }) => (
          Boolean(milestones.installExtension && extensionConnected)
        ),
        cannotAchieve: !config.links[getExtensionBrowserName()],
        // TODO: add link to safari extension.
        isApplicable: () => !isSafari(),
      },
      {
        stepId: 'locateExtension',
        component: LocateTheExtension,
      },
      {
        stepId: 'autosavePassword',
        component: AutosavePassword,
      },
      {
        stepId: 'autofillForms',
        component: AutofillLoginForms,
      },
      {
        stepId: 'navigateToSite',
        component: NavigateToSite,
      },
      {
        stepId: 'openWebDashboard',
        component: OpenWebDashboard,
      },
    ],
  },
  {
    tileId: 'importPasswords',
    link: 'import-your-passwords',
    steps: [
      {
        stepId: 'howToImportPasswords',
        useCustomCompletion: true,
        component: HowToImportPasswords,
      },
    ],
  },
  {
    tileId: 'disableSavingPasswords',
    link: 'disable-saving-passwords',
    steps: [
      {
        stepId: 'howToDisableVideo',
        component: HowToDisableVideo,
        useCustomCompletion: true,
      },
      {
        stepId: 'whyDisable',
        component: WhyDisableSavingPasswords,
      },
      {
        stepId: 'disableSavingInstallExtension',
        component: DisableSavingInstallExtension,
        isAchieved: ({ milestones, extensionConnected }) => (
          Boolean(milestones.disableSavingInstallExtension && extensionConnected)
        ),
        cannotAchieve: !config.links[getExtensionBrowserName()],
      },
      {
        stepId: 'howToDisable',
        component: HowToDisableSavingPasswords,
        isApplicable: () => Boolean(config.links[getExtensionBrowserName()]) && !isSafari(),
      },
    ],
  },
  {
    tileId: 'useAviraPWMOnMobile',
    link: 'use-password-manager-on-mobile',
    steps: [
      {
        stepId: 'whyUsePWMOnMobile',
        component: WhyUsePwmOnMobile,
      },
      {
        stepId: 'syncDataAcrossDevices',
        component: SynchronizeYourData,
      },
      {
        stepId: 'getMobileApps',
        component: MobileAppLinks,
      },
      {
        stepId: 'activateAutofillMobile',
        component: HowToActivateAutofillMobile,
        useCustomCompletion: true,
      },
    ],
  },
];
