import MixpanelLib from 'mixpanel-lib';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import config from '../config';
import debug from '../debug';

const INSTALLATION_COOKIE_NAME = 'installation_id';
const INSTALLATION_DISTINCT_ID_COOKIE_NAME = 'installation_distinct_id';
const ONE_YEAR = 365;

let privateInstallationId = null;
let privateInstallationDistinctId = null;

function generateInstallationId() {
  const digits = new Array(40);

  for (let i = 0; i < digits.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    digits[i] = (Math.random() * 16 | 0).toString(16);
  }

  return digits.join('');
}

const mixpanel = MixpanelLib({
  XMLHttpRequest,
  userAgent: navigator.userAgent,
  btoa: btoa.bind(window),
});

mixpanel.init(config.mixpanel.token);

const log = debug.extend('mixpanel');

export default {
  track(event, props = {}) {
    log(`track "${event}" %O`, props);
    mixpanel.track(event, props);
  },

  getData(properties) {
    return mixpanel.getData(properties);
  },

  register(...args) {
    mixpanel.register(...args);
  },

  initialize(distinctId) {
    let installationId = Cookie.get(INSTALLATION_COOKIE_NAME);

    if (installationId == null) {
      installationId = generateInstallationId();
    }

    let installationDistinctId = Cookie.get(INSTALLATION_DISTINCT_ID_COOKIE_NAME);

    if (installationDistinctId == null) {
      installationDistinctId = uuidv4();
    }

    this.setInstallationId(installationId);
    this.setInstallationDistinctId(installationDistinctId);
    this.identify(distinctId);

    const superProps = {
      'Installation Id': installationId,
      Platform: config.platform,
      Version: config.version,
      BuildNumber: config.buildNumber,
    };

    mixpanel.register(superProps);
  },

  getInstallationId() {
    return privateInstallationId;
  },

  setInstallationId(id) {
    privateInstallationId = id;
    Cookie.set(INSTALLATION_COOKIE_NAME, id, { secure: true, expires: ONE_YEAR });
  },

  getInstallationDistinctId() {
    return privateInstallationDistinctId;
  },

  setInstallationDistinctId(id) {
    privateInstallationDistinctId = id;
    Cookie.set(
      INSTALLATION_DISTINCT_ID_COOKIE_NAME,
      id,
      { secure: true, expires: ONE_YEAR },
    );
  },

  resetInstallationDistinctId() {
    privateInstallationDistinctId = null;
    Cookie.remove(INSTALLATION_DISTINCT_ID_COOKIE_NAME);
  },

  identify(distinctId) {
    const id = distinctId
      ?? this.getInstallationDistinctId()
      ?? this.getInstallationId();

    mixpanel.identify(id);

    mixpanel.register({
      IsLoggedIn: !!distinctId,
    });
  },

  setLicense(license, type) {
    mixpanel.register({
      license,
      licenseType: type,
    });
  },

  remove(props) {
    // re setting props to null as at this time there is no unregister event on mixpanel-lib
    mixpanel.register(props.reduce((r, v) => {
      r[v] = null;
      return r;
    }, {}));
  },

  setFTUParameters(ftuABTestVersion, ftuExisting) {
    mixpanel.register({ ftuABTestVersion, ftuExisting });
  },

  setUnregisteredMode(isUnregisteredMode) {
    mixpanel.register({
      isUnregisteredMode,
    });
  },

  setTotalAccounts(totalAccounts) {
    mixpanel.register({
      totalAccounts,
    });
  },

  setTotalNotes(totalNotes) {
    mixpanel.register({
      totalNotes,
    });
  },

  setTotalCreditCards(totalCreditCards) {
    mixpanel.register({
      totalCreditCards,
    });
  },

  setTotalPasswordsGenerated(totalPasswordsGenerated) {
    mixpanel.register({
      totalPasswordsGenerated,
    });
  },
};
