import React, { useState, useEffect, useRef } from 'react';

import {
  Hook,
  Unhook,
  Console as ConsoleFeed,
  Decode,
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
} from 'console-feed';


import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Column from 'pwm-components/objects/Column';
import Grid from 'pwm-components/objects/Grid';
import TextButton from 'pwm-components/components/TextButton';
import asyncJsonStorage from '../../lib/asyncJsonStorage';

const Console: React.FC = ({ children }) => {
  const [hooked, setHooked] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [logs, setLogs] = useState<any[]>([]);
  const logAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hookedConsole = Hook(window.console, (log: any) => {
      setLogs((curLogs) => {
        const arr = [
          ...curLogs,
          Decode(log),
        ];

        return arr.slice(Math.max(arr.length - 99, 0));
      });
    });

    const previousOnError = window.onerror;
    // eslint-disable-next-line max-params
    const onError: OnErrorEventHandler = (event, source, lineno, colno, error) => {
      if (previousOnError) {
        previousOnError(event, source, lineno, colno, error);
      }

      console.error(event, source, lineno, colno, error);
    };

    window.onerror = onError;
    setHooked(true);

    // In IE11 this is automatically logged to the console, probably by some Promise polyfill.
    // If there's some quirky behavior with promise rejection and you can't see the console,
    // try enabling this event.
    // function onUnhandledRejection(event: PromiseRejectionEvent): void {
    //   console.error(event.reason);
    // }
    // window.onunhandledrejection = onUnhandledRejection;

    return () => {
      window.onerror = previousOnError;
      // delete window.onunhandledrejection;
      Unhook(hookedConsole);
    };
  }, []);

  useEffect(() => {
    if (logAreaRef.current) {
      logAreaRef.current.scrollTop = logAreaRef.current.scrollHeight;
    }
  }, [logs]);

  return (
    <>
      { hooked && children}
      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        zIndex={10000}
        height={showLog ? '50%' : '0'}
        bg="foregroundSecondary"
        pt={showLog ? 'xxs' : ''}
      >
        <Box position="absolute" top="-40px" right="10px">
          <Button size="mini" onClick={() => { setShowLog(!showLog); }}>Log</Button>
        </Box>
        <Box display="flex" flexDirection="column" height="100%">
          <Box bg="foreground" p="xxs">
            <Grid>
              <Column />
              <Column size="auto"><TextButton onClick={() => { window.location.reload(); }}>Reload</TextButton></Column>
              <Column size="auto"><TextButton onClick={() => { asyncJsonStorage.clear(); }}>Clear asyncStorage</TextButton></Column>
              <Column size="auto">
                <TextButton
                  onClick={() => {
                    localStorage.debug = 'dashboard:*';
                  }}
                >
                  Clear localStorage
                </TextButton>
              </Column>
              <Column size="auto">
                <TextButton onClick={() => setLogs([])}>Clear console</TextButton>
              </Column>
            </Grid>
          </Box>
          <Box ref={logAreaRef} overflow="scroll">
            <ConsoleFeed logs={logs} variant="dark" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Console;
