import React from 'react';
import {
  FormattedHTMLMessage, injectIntl, FormattedMessage,
} from 'react-intl';
import PropTypes from 'prop-types';
import ActionButton from '../../componentLib/ActionButton';
import config from '../../config';
import Card from '../../componentLib/Card';
import Checkbox from '../../componentLib/Checkbox';
import { getBrowser } from '../../lib/UserAgent';
import nativeMsgCode from '../nativeMessagingErrorCode';
import intlShape from '../../lib/intlShape';

const { supportedBrowser } = config.nativeMessaging;
const currentBrowser = getBrowser().toLowerCase();

class ImportBrowserCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: {},
      disabled: {},
      actionButtonDisabled: false,
    };

    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.setCheckboxState = this.setCheckboxState.bind(this);
    this.onActionButtonClick = this.onActionButtonClick.bind(this);
    this.onActionButtonClickInstallAvira = this.onActionButtonClickInstallAvira.bind(this);
    this.checkActionButtonStatus = this.checkActionButtonStatus.bind(this);
  }

  componentDidMount() {
    this.setCheckboxState();
  }

  componentDidUpdate(prevProps) {
    const { browserData } = this.props;
    if (prevProps.browserData !== browserData) {
      this.setCheckboxState();
    }
  }

  onCheckboxChange(key, value) {
    const { checked } = this.state;
    this.setState({
      checked: {
        ...checked,
        [key]: value,
      },
    }, () => this.checkActionButtonStatus());
  }

  onActionButtonClick() {
    let credentials = [];
    let cutHeader = false;
    const { browserData, onActionClick } = this.props;
    const { checked } = this.state;
    Object.keys(browserData).forEach((key) => {
      const data = browserData[key];
      if (checked[data.browser]) {
        if (cutHeader) {
          data.credentials.shift();
        }
        credentials = credentials.concat(data.credentials);
        cutHeader = true;
      }
    });

    onActionClick(credentials, checked);
  }

  onActionButtonClickInstallAvira() {
    const { lang } = this.props;
    window.open(`${config.nativeMessaging.connectDownloadUrl}/avira_${lang}_pass0.exe`);
  }

  onActionButtonClickInstallExtension() {
    window.open(`${config.links[currentBrowser]}?subSource=Browser%20Import`);
  }

  setCheckboxState() {
    const checked = {};
    const disabled = {};

    supportedBrowser.forEach((browser) => {
      checked[browser] = this.isChecked(browser);
      disabled[browser] = this.isDisabled(browser);
    });

    this.setState({ checked, disabled }, this.checkActionButtonStatus);
  }

  isChecked(browser) {
    // we lack the permsionns to do a check if a browser has data or not, therefore we just
    // pre-select all supported browsers.
    const { browserData } = this.props;
    if (browserData.errorCode === nativeMsgCode.noNativeMessagingPermissions) {
      return true;
    }

    let hasCredentials = false;
    if (browserData[browser] && browserData[browser].credentials.length > 1) {
      hasCredentials = true;
    }

    return hasCredentials;
  }

  isDisabled(browser) {
    const { browserData } = this.props;
    return (
      !browserData[browser]
      || browserData[browser].credentials <= 0
    );
  }

  checkActionButtonStatus() {
    const { browserData, extensionInstalled } = this.props;
    let actionButtonDisabled = false;
    let browserSelected = false;
    const { errorCode } = browserData;

    if ((errorCode !== nativeMsgCode.noError
      && errorCode !== nativeMsgCode.noNativeMessagingPermissions)
      || !extensionInstalled) {
      this.setState({ actionButtonDisabled });
      return;
    }

    supportedBrowser.forEach((browser) => {
      const { checked } = this.state;
      const currentBrowserData = browserData[browser] || { credentials: [] };
      if (checked[browser]) {
        browserSelected = true;
        if (errorCode === nativeMsgCode.noError) {
          if (currentBrowserData.credentials && currentBrowserData.errorCode !== 0) {
            actionButtonDisabled = true;
          }
        }
      }
    });

    if (!browserSelected) actionButtonDisabled = true;

    this.setState({ actionButtonDisabled });
  }

  render() {
    const {
      intl, browserData, performRetry, loading, extensionInstalled,
    } = this.props;
    const { checked, disabled, actionButtonDisabled } = this.state;
    let actionButtonColor = 'green';
    let actionbuttonOnClick = this.onActionButtonClick;
    let cardContent;
    let checkboxMessage;
    let cardMessage;
    let actionButtonLabel = 'dashboard.settings.import.button.fromBrowser';
    const retryElement = (
      <a
        className="u-cursor-p u-outline-none u-txt-underline"
        onClick={performRetry}
        role="link"
        tabIndex="0"
      >
        <FormattedHTMLMessage
          id="dashboard.settings.import.fromBrowser.error.retry"
        />
      </a>
    );

    switch (browserData.errorCode) {
      case nativeMsgCode.noError:
        cardMessage = (
          <div className="u-mb-xl u-txt-strong">
            <FormattedMessage id="dashboard.settings.import.fromBrowser" />
          </div>
        );
        cardContent = (
          supportedBrowser.map((browser) => {
            let accountNumber = 0;
            const currentBrowserData = browserData[browser] || { credentials: [] };
            if (currentBrowserData.credentials.length > 1) {
              accountNumber = currentBrowserData.credentials.length - 1;
            }

            switch (currentBrowserData.errorCode) {
              case nativeMsgCode.DBError:
                actionButtonColor = 'red';
                checkboxMessage = (
                  <>
                    &nbsp;
                    <span className="u-color-red">
                      <FormattedHTMLMessage
                        id={`dashboard.settings.import.fromBrowser.error.${browser}`}
                      />
                    </span>
                    &nbsp;
                    {retryElement}
                  </>
                );
                break;
              case nativeMsgCode.Oops:
                actionButtonColor = 'red';
                checkboxMessage = (
                  <>
                    &nbsp;
                    <span className="u-color-red">
                      <FormattedHTMLMessage id="dashboard.settings.import.fromBrowser.error.oops" />
                    </span>
                    &nbsp;
                    {retryElement}
                  </>
                );
                break;
              case nativeMsgCode.NotSupported:
                checkboxMessage = (
                  <>
                    &nbsp;
                    <FormattedHTMLMessage
                      id="dashboard.settings.import.fromBrowser.error.notSupported"
                    />
                  </>
                );
                break;
              default:
                checkboxMessage = (
                  <FormattedHTMLMessage
                    id="dashboard.settings.import.passwords"
                    values={{ accounts: accountNumber }}
                  />
                );
                break;
            }

            return (
              <React.Fragment key={browser}>
                <div className="u-mb-xs">
                  <Checkbox
                    className="u-txt-pica"
                    name={browser}
                    labelId={`dashboard.settings.import.fromBrowser.${browser}`}
                    checked={!!checked[browser]}
                    disabled={!!disabled[browser]}
                    onChange={this.onCheckboxChange}
                  />
                  {checkboxMessage}
                </div>
              </React.Fragment>
            );
          })
        );
        break;
      case nativeMsgCode.noNativeMessagingAppFound:
        actionbuttonOnClick = this.onActionButtonClickInstallAvira;
        actionButtonColor = 'red';
        actionButtonLabel = 'dashboard.settings.import.button.installAviraClient';
        cardContent = (
          <div className="u-mb-xl">
            <FormattedMessage id="dashboard.settings.import.installAviraClient" />
          </div>
        );
        break;
      case nativeMsgCode.noNativeMessagingPermissions:
        cardMessage = (
          <div className="u-mb-xl u-txt-strong">
            <FormattedMessage id="dashboard.settings.import.fromBrowser" />
          </div>
        );
        cardContent = supportedBrowser.map(browser => (
          <React.Fragment key={browser}>
            <div className="u-mb-xs">
              <Checkbox
                className="u-txt-pica"
                name={browser}
                labelId={`dashboard.settings.import.fromBrowser.${browser}`}
                checked={!!checked[browser]}
                onChange={this.onCheckboxChange}
              />
            </div>
          </React.Fragment>
        ));
        break;
      case nativeMsgCode.noNativeMessagingCompatibleExt:
        cardContent = (
          <div className="u-mb-xl">
            <FormattedMessage id="dashboard.settings.import.extensionOutdated" />
          </div>
        );
        break;
      default:
        if (extensionInstalled && loading) {
          actionButtonColor = 'green';
          cardContent = (
            <div className="u-mb-xl">
              <FormattedMessage id="dashboard.settings.import.loading" />
            </div>
          );
          break;
        }

        actionButtonColor = 'red';
        actionButtonLabel = extensionInstalled ? (
          'dashboard.settings.import.button.installAviraClient'
        ) : (
          'dashboard.settings.import.button.installExtension'
        );
        actionbuttonOnClick = extensionInstalled ? (
          this.onActionButtonClickInstallAvira
        ) : (
          this.onActionButtonClickInstallExtension
        );
        cardContent = extensionInstalled ? (
          <div className="u-mb-xl">
            <FormattedMessage id="dashboard.settings.import.installAviraClient" />
          </div>
        ) : (
          <div className="u-mb-xl">
            <FormattedMessage id="dashboard.settings.import.installExtension" />
          </div>
        );
        break;
    }

    let actionButton = (
      <div className="u-d-ib">
        <ActionButton
          type="button"
          block={false}
          label={intl.formatMessage({
            id: actionButtonLabel,
          })}
          onClick={actionbuttonOnClick}
          color={actionButtonColor}
          loading={loading}
          disabled={actionButtonDisabled}
        />
      </div>
    );

    if (browserData.errorCode === nativeMsgCode.noNativeMessagingCompatibleExt) {
      actionButton = null;
    }

    return (
      <Card
        title="dashboard.settings.import.title.fromBrowser"
        className="u-fx-g-1 u-fx-s-1 u-fx-b-0"
        classNameTile="u-fx-1"
      >
        <div className="u-txt-left u-height-full u-fx u-fx-d-c u-fx-jc-sb">
          {cardMessage}
          <div className="u-mb-xxl">
            {cardContent}
          </div>
          {actionButton}
        </div>
      </Card>
    );
  }
}

ImportBrowserCard.propTypes = {
  browserData: PropTypes.shape(),
  errorCode: PropTypes.number,
  extensionInstalled: PropTypes.bool,
  onActionClick: PropTypes.func,
  performRetry: PropTypes.func,
  intl: intlShape.isRequired,
  lang: PropTypes.string,
  loading: PropTypes.bool,
};

ImportBrowserCard.defaultProps = {
  browserData: {},
  onActionClick: () => { },
  performRetry: () => { },
  errorCode: 0,
  extensionInstalled: false,
  lang: null,
  loading: false,
};

export default injectIntl(ImportBrowserCard);
