import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

import styled from 'pwm-components/styled';
import Grid from 'pwm-components/objects/Grid';
import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Paragraph from 'pwm-components/components/Paragraph';
import TextButton from 'pwm-components/components/TextButton';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import DomainImage from 'pwm-components/components/DomainImage';
import DualButton from 'pwm-components/components/DualButton';
import PasswordInput from 'pwm-components/components/PasswordInput';
import ChevronArrow from 'pwm-components/components/ChevronArrow';
import CopyIcon from 'pwm-components/icons/Copy';
import LoginIcon from 'pwm-components/icons/Login';
import { withTooltip } from 'pwm-components/components/Tooltip';
import { Subdomain, MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/Account';

import config from '../../config';
import Card from '../../componentLib/CardWrapper';
import FakeInputField from '../../componentLib/FakeInputField';
import UsernameAutoSuggestContainer from '../../componentLib/UsernameAutoSuggestContainer';
import DomainInputWithAssociatedUrls from '../../componentLib/DomainInputWithAssociatedUrls';
import MyDataDetailsContainer, { Props as MyDataDetailsProps } from '../../myData/components/MyDataDetailsContainer';

import tabIndex from '../../lib/TabIndexConfig';
import clipboardCopy from '../../lib/CopyToClipboard';
import * as SpotlightAPI from '../../lib/SpotlightAPI';
import * as DataValidatorErrors from '../../lib/DataValidator';
import { getDomain, constructValidUrl } from '../../lib/DomainNameHelper';
import { handleDetailsChange, mergeDetails } from '../../lib/MyDataHelper';
import { validateEmail, validatePasswordStrength } from '../../lib/AuthenticationValidator';

import TimeToHack from './TimeToHack';
import PasswordHistory from './PasswordHistory';
import GeneratePassword from './GeneratePassword';
import { buttonColorHelper } from './passwordStrengthHelper';
import { isAttached, filterFiles } from '../../files/helpers/FileDataHelper';

const StyledLoginIcon = styled(LoginIcon)`
  &:hover {
    color: ${({ theme: { colors } }) => colors.white};
    fill: ${({ theme: { colors } }) => colors.white};
  }
`;

const StyledDualButton = styled(DualButton)`
  border-color: ${({ theme: { colors } }) => colors.white};
`;

StyledDualButton.displayName = 'StyledDualButton';

const USERNAME_PROPERTIES = {
  key: 'username',
  label: 'dashboard.account.details.username',
};

const EMAIL_PROPERTIES = {
  key: 'email',
  label: 'dashboard.account.details.email',
};

const TRACKING_CONTEXT = 'details';

const CopyIconWithTooltip = withTooltip(CopyIcon);

type Props = MyDataDetailsProps & {
  account?: any;
  accounts?: any;
  accountDetails?: any;
  extensionInstalled: boolean;
  onLoginClick: Function;
  onCopyClick: Function;
  onRevealPasswordClick: Function;
  onAssociatedUrlShowClicked: (tracking: object) => void;
  onAssociatedUrlAddClicked: (tracking: object) => void;
  onAssociatedUrlRemoveClicked: (tracking: object) => void;
  onAssociatedUrlNavigateClicked: (tracking: object) => void;
  onAccountSaveClick: Function;
  oe?: {
    email?: string;
  };
  prefilledValues?: any;
  defaultUsername?: string;
  onDotMenuOpen?: () => void;
}

type State = {
  account: any;
  initialAccount: any;
  changedKeys: any;
  errors: any;
  fields: Array<{ key: string; label: string }>;
  generateTooltipVisible: boolean;
  labelAutoFilled: boolean;
  loading: boolean;
  revealPassword: boolean;
  passwordAutoGenerated: boolean;
  showGlobalErrors: boolean;
  strength: any;
  showPasswordHistory: boolean;
  showLinkedUrl: boolean;
};

class AddEditAccountPage extends React.Component<Props, State> {
  private setPasswordStrengthDebounced: Function;

  private domainRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  private passwordRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  private autofillTimeoutIds: number[] = [];

  public static defaultProps = {
    account: undefined,
    accounts: undefined,
    addMode: false,
    oe: {
      email: '',
    },
    onDelete: () => { },
    onFavoriteClick: () => { },
    prefilledValues: undefined,
    defaultUsername: '',
  };

  private static getErrorMessageId(field: string, errorType: string): string | null {
    if (errorType === DataValidatorErrors.ERROR_FORMAT) {
      return `dashboard.account.error.${field}.format`;
    } if (errorType === DataValidatorErrors.ERROR_REQUIRED) {
      return `dashboard.account.error.${field}.required`;
    } if (errorType === DataValidatorErrors.ERROR_CUSTOM) {
      if (field === 'label') {
        return `dashboard.account.error.${field}.required`;
      }
      return `dashboard.account.error.${field}.custom`;
    }
    return null;
  }

  private static getFieldsFromAccount(account: any): Array<{ key: string; label: string }> {
    const fields = [];
    if (!account.username && account.email) {
      fields.push({
        key: EMAIL_PROPERTIES.key,
        label: USERNAME_PROPERTIES.label,
      });
    } else {
      fields.push(USERNAME_PROPERTIES);
      if (account.email) {
        fields.push(EMAIL_PROPERTIES);
      }
    }
    return fields;
  }

  // eslint-disable-next-line max-statements
  constructor(props: Props) {
    super(props);
    const {
      account = {}, addMode, prefilledValues, defaultUsername,
    } = props;

    if (addMode) {
      Object.assign(
        account,
        prefilledValues || { username: defaultUsername },
      );
    }

    this.state = {
      account,
      changedKeys: { ...prefilledValues },
      errors: {},
      fields: AddEditAccountPage.getFieldsFromAccount(account),
      generateTooltipVisible: false,
      initialAccount: account,
      labelAutoFilled: (!account.label),
      loading: false,
      revealPassword: false,
      strength: validatePasswordStrength(account.password),
      passwordAutoGenerated: false,
      showGlobalErrors: false,
      showPasswordHistory: false,
      showLinkedUrl: false,
    };

    this.getFieldError = this.getFieldError.bind(this);
    this.cleanupAnimationTimeout = this.cleanupAnimationTimeout.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.onRevealPasswordClick = this.onRevealPasswordClick.bind(this);
    this.onCopyClick = this.onCopyClick.bind(this);
    this.onUseGeneratedPassword = this.onUseGeneratedPassword.bind(this);
    this.closeGeneratePasword = this.closeGeneratePasword.bind(this);
    this.setPasswordStrength = this.setPasswordStrength.bind(this);
    this.onFavoriteClick = this.onFavoriteClick.bind(this);
    this.focusInputField = this.focusInputField.bind(this);
    this.displayPasswordHistory = this.displayPasswordHistory.bind(this);
    this.displayLinkedUrls = this.displayLinkedUrls.bind(this);
    this.closePasswordHistory = this.closePasswordHistory.bind(this);
    this.closeLinkedUrls = this.closeLinkedUrls.bind(this);
    this.onAutoLoginClicked = this.onAutoLoginClicked.bind(this);
    this.setPasswordStrengthDebounced = debounce(
      this.setPasswordStrength.bind(this), 300, { trailing: true },
    );
  }

  public componentDidMount(): void {
    const { addMode, prefilledValues } = this.props;

    if (addMode) {
      const focusField = prefilledValues ? this.passwordRef : this.domainRef;
      this.focusInputField(focusField);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const { account: accountProp } = this.props;
    const { account: accountState, changedKeys: currentChangedKeys } = this.state;
    if (nextProps.account !== accountProp) {
      const { account = {} } = nextProps;
      const {
        updatedData: updatedAccount,
        updatedChangedKeys: changedKeys,
      } = mergeDetails(account, accountState, Object.keys(currentChangedKeys));

      if (nextProps.addMode) {
        account.username = nextProps.oe && nextProps.oe.email;
      }

      this.setState({
        account: updatedAccount,
        changedKeys,
        fields: AddEditAccountPage.getFieldsFromAccount(updatedAccount),
        initialAccount: account,
        labelAutoFilled: (!account.label),
        loading: false,
        revealPassword: false,
        strength: validatePasswordStrength(updatedAccount.password),
      });
    }
  }

  private onPasswordChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.isTrusted) {
      this.onAccountChange('password', e.target.value);
    }
  }

  private onAccountChange(key: string, value: any, callback = () => { }): void {
    const {
      initialAccount, account, errors, changedKeys: currentChangedKeys,
    } = this.state;

    const {
      newChangeObj,
      changedKeys,
    } = handleDetailsChange(key, value, initialAccount, currentChangedKeys);

    let { labelAutoFilled } = this.state;
    if (key === 'label') {
      labelAutoFilled = false;
    } else if (key === 'domain' && !account.label) {
      labelAutoFilled = true;
    }

    if (key === 'password') {
      this.setPasswordStrengthDebounced(value);
    }

    this.setState({
      account: { ...account, ...newChangeObj },
      errors: { ...errors, [key]: null },
      changedKeys,
      labelAutoFilled,
    }, callback);
  }

  private onFavoriteClick(): void {
    const { onFavoriteClick, account } = this.props;
    if (onFavoriteClick) {
      onFavoriteClick(account);
    }
  }

  private onUseGeneratedPassword(value: string): void {
    this.cleanupAnimationTimeout();

    this.setState({
      generateTooltipVisible: false,
      passwordAutoGenerated: true,
    }, () => this.onAccountChange('password', value));
  }

  private onRevealPasswordClick(): void {
    const { onRevealPasswordClick, accountDetails } = this.props;

    if (accountDetails) {
      onRevealPasswordClick(TRACKING_CONTEXT, 'revealPassword', accountDetails.account);
    }
  }

  private onCopyClick(key: string): void {
    const { onCopyClick, accountDetails } = this.props;

    if (accountDetails) {
      onCopyClick(
        TRACKING_CONTEXT,
        key,
        accountDetails.account,
      );
    }
  }

  // eslint-disable-next-line complexity
  private onAutoLoginClicked(e: React.MouseEvent, subdomain?: Subdomain): void {
    const { extensionInstalled, onLoginClick } = this.props;
    const { account } = this.state;
    const subdomainUrl = subdomain?.loginUrl ?? subdomain?.subdomain;

    let loginActionUrl = account.login_url;

    if (!loginActionUrl || (
      getDomain(account.domain) !== getDomain(loginActionUrl)
    )) {
      loginActionUrl = constructValidUrl(account.domain);
    }

    if (subdomainUrl) {
      loginActionUrl = constructValidUrl(subdomainUrl);
    }

    if (extensionInstalled && !subdomainUrl) {
      e.preventDefault();
      onLoginClick(account.id, loginActionUrl, TRACKING_CONTEXT);
    } else if (config.spotlight) {
      SpotlightAPI.openURL(loginActionUrl);
    } else {
      window.open(loginActionUrl);
    }
  }

  private setPasswordStrength(password: string): void {
    this.setState({
      strength: validatePasswordStrength(password),
    });
  }

  private getFieldError(field: string): string | null {
    const { intl } = this.props;
    const { errors } = this.state;
    if (!errors || !errors[field]) {
      return null;
    }

    const errorMessageId = AddEditAccountPage.getErrorMessageId(field, errors[field]);
    if (errorMessageId) {
      return intl.formatMessage({ id: errorMessageId });
    }

    return null;
  }

  private cleanupAnimationTimeout(): void {
    if (this.autofillTimeoutIds && this.autofillTimeoutIds.length > 0) {
      this.autofillTimeoutIds.forEach(id => clearTimeout(id));
      this.autofillTimeoutIds = [];
    }
  }

  // eslint-disable-next-line max-statements, complexity
  private async handleSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ignoreDuplicateAccount = false,
    ignoreDomainNotInSubdomain = false,
  ): Promise<void> {
    e.preventDefault();

    const { addMode, onAccountSaveClick } = this.props;
    const {
      account: prevAccount, changedKeys: prevChangedKeys, labelAutoFilled, passwordAutoGenerated,
    } = this.state;
    const prevState = { ...this.state };
    const account = { ...prevAccount };
    const changedKeys = new Set(Object.keys(prevChangedKeys));

    // ensure default username is captured in changedKeys
    if (addMode) changedKeys.add('username');

    // if email is empty and user name is an email than
    if (!account.email && account.username && validateEmail(account.username.toLowerCase())) {
      account.email = account.username;
      account.username = '';
      changedKeys.add('email').add('username');
    } else if (account.email && !account.username && !validateEmail(account.email.toLowerCase())) {
      account.username = account.email;
      account.email = '';
      changedKeys.add('email').add('username');
    }

    if (ignoreDomainNotInSubdomain && account.subdomain) {
      account.subdomain = account.subdomain.map((entry: Subdomain) => {
        if (entry.visible) {
          entry.visible = false;
        }

        return entry;
      });
      changedKeys.add('subdomain');
    }

    try {
      this.setState({ loading: true });
      const changedProps = {
        ...pick(account, Array.from(changedKeys)),
      };

      // update the account state with change values so that it doesnt use mergeDetails
      // when the componentWillRecieveProps is called
      const { account: currentAccountState } = this.state;
      this.setState({
        account: {
          ...currentAccountState,
          ...changedProps,
          domain: getDomain(account.domain),
        },
      });

      const ignoreDuplicateFlag = !addMode || ignoreDuplicateAccount;
      const ignoreDomainNotInSubdomainFlag = !(
        !addMode
        && prevChangedKeys.domain
        && (account.subdomain && account.subdomain.some((entry: Subdomain) => entry.visible))
      ) || ignoreDomainNotInSubdomain;

      await onAccountSaveClick(account.id, changedProps, {
        ignoreDuplicateAccount: ignoreDuplicateFlag,
        ignoreDomainNotInSubdomain: ignoreDomainNotInSubdomainFlag,
        labelAutoFilled,
        passwordAutoGenerated,
      });
    } catch (errors) {
      // check errors for global errors and handle it differently.
      const globalErrors = Object.keys(errors)
        .filter(key => (errors[key] === DataValidatorErrors.ERROR_GLOBAL));
      const showGlobalErrors = Object.keys(errors).length === globalErrors.length;
      this.setState({
        ...prevState,
        errors,
        showGlobalErrors,
        loading: false,
      });
    }
  }

  private focusInputField(ref: React.RefObject<HTMLInputElement>): void {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }

  private displayPasswordHistory(): void {
    this.setState({ showPasswordHistory: true });
  }

  private displayLinkedUrls(): void {
    const { showLinkedUrl } = this.state;
    if (showLinkedUrl) {
      this.closeLinkedUrls();
    } else {
      this.setState({ showLinkedUrl: true });
    }
  }

  private closePasswordHistory(): void {
    this.setState({ showPasswordHistory: false });
  }

  private closeLinkedUrls(): void {
    this.setState({ showLinkedUrl: false });
  }

  private closeGeneratePasword(): void {
    this.setState({ generateTooltipVisible: false });
  }

  // eslint-disable-next-line complexity
  public render(): JSX.Element {
    const {
      addMode,
      intl,
      files: filesProp,
      onAssociatedUrlAddClicked,
      onAssociatedUrlRemoveClicked,
      onAssociatedUrlNavigateClicked,
      onDotMenuOpen,
    } = this.props;
    const {
      account,
      initialAccount,
      changedKeys,
      strength,
      generateTooltipVisible,
      loading,
      showGlobalErrors,
      labelAutoFilled,
      showPasswordHistory,
      showLinkedUrl,
      errors: { duplicateAccount, domainNotInSubdomain },
    } = this.state;

    let totalAssociatedUrls: number;
    if (!addMode) {
      totalAssociatedUrls = account.subdomain
        ? account.subdomain.filter((subdomainData: Subdomain) => subdomainData?.visible).length
        : 0;
    } else {
      totalAssociatedUrls = 0;
    }
    const files = filterFiles(filesProp, account.id);

    return (
      <MyDataDetailsContainer
        {...this.props}
        focusNameField
        entityName="Account"
        nameLabel="label"
        listViewPath="/mydata/passwords"
        confirmDeleteId="dashboard.account.details.confirmDelete"
        namePlaceholderId="dashboard.account.details.namePlaceholder"
        tagsInstructionId="dashboard.account.details.tagInstruction"
        attachmentInstructionId="dashboard.account.details.attachmentInstruction"
        attachmentGetProId="dashboard.account.details.upsellPro"
        enableSyncInstructionId="dashboard.account.details.enableSync"
        data={account}
        files={files}
        loading={loading}
        isFavorite={account.is_favorite}
        changedKeys={changedKeys}
        nameValue={(labelAutoFilled ? account.domain : account.label) || ''}
        createdDate={account.createdAt || ''}
        modifiedDate={account.modifiedAt || ''}
        error={this.getFieldError('label')}
        extraMenuItems={[
          {
            labelId: 'dashboard.account.details.passwordHistory',
            menuItemAttrs: {
              id: 'a-password-history-button',
              onClick: this.displayPasswordHistory,
            },
          },
        ]}
        iconElement={(
          <DomainImage
            display="flex"
            attached={isAttached(files)}
            domain={getDomain(account.domain) ?? ''}
            label={account.label}
            storage={localStorage}
          />
        )}
        onMenuOpen={onDotMenuOpen}
        headerPopup={!addMode ? (
          <PasswordHistory
            visible={showPasswordHistory}
            onCloseClick={this.closePasswordHistory}
            onOutsideClick={this.closePasswordHistory}
          />
        ) : null}
        onChange={this.onAccountChange}
        onFavoriteClick={this.onFavoriteClick}
        handleSubmit={this.handleSubmit}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box mb="m" flex="0 0 auto">
            <DomainInputWithAssociatedUrls
              id="a-domain-input"
              tabIndex={tabIndex.mainFormFields}
              domain={account.domain || ''}
              maxLength={MAX_LENGTHS.domain}
              label={intl.formatMessage({ id: 'dashboard.account.details.url', defaultMessage: 'URL' })}
              placeholder={intl.formatMessage({ id: 'dashboard.account.details.urlPlaceholder', defaultMessage: 'e.g. Avira.com' })}
              error={this.getFieldError('domain')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onAccountChange('domain', e.target.value.trim())}
              icons={!addMode && !!(account.login_url || account.domain) ? (
                <StyledDualButton
                  size="small"
                  variant="login"
                  contentLeft={(
                    <StyledLoginIcon className="a-login-icon" variant="light" style={{ position: 'relative', top: '-2px' }} />
                  )}
                  onClickLeft={(e: React.MouseEvent) => {
                    this.onAutoLoginClicked(e);
                  }}
                  contentRight={(
                    <ChevronArrow className="a-subdomains-dropdown-menu-button" direction="down" style={{ verticalAlign: 'middle', position: 'relative', top: '-1px' }} />
                  )}
                  onClickRight={() => {
                    const { onAssociatedUrlShowClicked } = this.props;
                    onAssociatedUrlShowClicked({
                      domain: account.domain,
                      totalAssociatedUrls,
                      context: TRACKING_CONTEXT,
                    });
                    this.displayLinkedUrls();
                  }}
                />
              ) : null}
              linkedUrls={account.subdomain || []}
              showDropdownMenu={showLinkedUrl}
              closeLinkedUrls={this.closeLinkedUrls}
              onAccountChange={this.onAccountChange}
              onLoginClick={this.onAutoLoginClicked}
              onAssociatedUrlAddClicked={onAssociatedUrlAddClicked}
              onAssociatedUrlRemoveClicked={onAssociatedUrlRemoveClicked}
              onAssociatedUrlNavigateClicked={onAssociatedUrlNavigateClicked}
            />
          </Box>
          {initialAccount.email && (
            <Box mb="m" flex="0 0 auto">
              <UsernameAutoSuggestContainer
                id="a-email-input"
                tabIndex={tabIndex.mainFormFields}
                value={account.email}
                maxLength={MAX_LENGTHS.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onAccountChange('email', e.target.value)}
                onSelect={(value: string) => this.onAccountChange('email', value)}
                label={intl.formatMessage({ id: initialAccount.username ? 'dashboard.account.details.email' : 'dashboard.account.details.username' })}
                placeholder={intl.formatMessage({ id: 'dashboard.account.details.usernamePlaceholder' })}
                error={this.getFieldError('email')}
                icons={[
                  <TextButton
                    focusable
                    tabIndex={tabIndex.optionalActionFields}
                    onClick={async () => {
                      clipboardCopy(account.email);
                      this.onCopyClick('copyEmail');
                    }}
                  >
                    <CopyIconWithTooltip
                      info
                      variant="dark"
                      tooltipAlign="right"
                      tooltipContent={(
                        <FormattedMessage
                          id="dashboard.account.details.copyAction"
                          defaultMessage="Copy"
                        />
                      )}
                      feedbackContent={(
                        <FormattedMessage
                          id="dashboard.account.details.copyActionFeedback"
                          defaultMessage="Copied"
                        />
                      )}
                    />
                  </TextButton>,
                ]}
              />
            </Box>
          )}
          {(initialAccount.username || (!initialAccount.username && !initialAccount.email))
            && (
              <Box mb="m" flex="0 0 auto">
                <UsernameAutoSuggestContainer
                  id="a-username-input"
                  tabIndex={tabIndex.mainFormFields}
                  value={account.username}
                  maxLength={MAX_LENGTHS.username}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onAccountChange('username', e.target.value)}
                  onSelect={(value: string) => this.onAccountChange('username', value)}
                  label={intl.formatMessage({ id: 'dashboard.account.details.username' })}
                  placeholder={intl.formatMessage({ id: 'dashboard.account.details.usernamePlaceholder' })}
                  error={this.getFieldError('username')}
                  icons={[
                    <TextButton
                      id="a-copy-username-icon"
                      key="copyUsername"
                      focusable
                      tabIndex={tabIndex.optionalActionFields}
                      onClick={async () => {
                        clipboardCopy(account.username);
                        this.onCopyClick('copyUsername');
                      }}
                    >
                      <CopyIconWithTooltip
                        info
                        variant="dark"
                        tooltipAlign="right"
                        tooltipContent={(
                          <FormattedMessage
                            id="dashboard.account.details.copyAction"
                            defaultMessage="Copy"
                          />
                        )}
                        feedbackContent={(
                          <FormattedMessage
                            id="dashboard.account.details.copyActionFeedback"
                            defaultMessage="Copied"
                          />
                        )}
                      />
                    </TextButton>,
                  ]}
                />
              </Box>
            )}

          <Box flex="0 0 auto">
            {/* Prevent Firefox from autofilling the password field */}
            <FakeInputField type="password" />
            <PasswordInput
              id="a-password-input"
              ref={this.passwordRef}
              suppressAutofill
              score={strength && strength.score}
              tabIndex={tabIndex.mainFormFields}
              showLabel={intl.formatMessage({ id: 'dashboard.account.details.showPassword' })}
              hideLabel={intl.formatMessage({ id: 'dashboard.account.details.hidePassword' })}
              autoComplete="off"
              value={account.password}
              maxLength={MAX_LENGTHS.password}
              onChange={this.onPasswordChanged}
              onPasswordVisibilityToggled={this.onRevealPasswordClick}
              label={intl.formatMessage({ id: 'dashboard.account.details.password' })}
              error={this.getFieldError('password')}
              icons={(
                <TextButton
                  id="a-copy-password-icon"
                  key="copyPassword"
                  focusable
                  tabIndex={tabIndex.optionalActionFields}
                  onClick={async () => {
                    clipboardCopy(account.password);
                    this.onCopyClick('copyPassword');
                  }}
                >
                  <CopyIconWithTooltip
                    info
                    variant="dark"
                    tooltipAlign="right"
                    tooltipContent={(
                      <FormattedMessage
                        id="dashboard.account.details.copyPasswordAction"
                        defaultMessage="Click to copy the password to Clipboard"
                      />
                    )}
                    feedbackContent={(
                      <FormattedMessage
                        id="dashboard.account.details.copyPasswordActionFeedback"
                        defaultMessage="Copied to Clipboard"
                      />
                    )}
                  />
                </TextButton>
              )}
            />
          </Box>
          <Card variant="secondary" p="m">
            <Grid height="100%" size="no-padding" vertical>
              <Box minHeight="54px" mt="xxs" mb="xs">
                {strength && (
                  <TimeToHack
                    score={strength.score}
                    time={strength.timeToCrack}
                  />
                )}
              </Box>
              <Button
                id="a-change-password-action"
                size="mini"
                tabIndex={tabIndex.optionalActionFields}
                variant={buttonColorHelper(strength && strength.score)}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.stopPropagation();
                  this.setState({ generateTooltipVisible: !generateTooltipVisible });
                }}
              >
                {addMode
                  ? (
                    <FormattedMessage
                      id="dashboard.account.details.generatePassword"
                      defaultMessage="Generate Password"
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard.account.details.changePassword"
                      defaultMessage="Change Password"
                    />
                  )}
              </Button>
            </Grid>
          </Card>
          <Box position="relative">
            {
              generateTooltipVisible
              && (
                <GeneratePassword
                  onUsePassword={this.onUseGeneratedPassword}
                  onCloseClick={this.closeGeneratePasword}
                  onOutsideClick={this.closeGeneratePasword}
                />
              )
            }
          </Box>
        </Box>
        <ConfirmationDialog
          confirmButtonType="alert"
          show={duplicateAccount && showGlobalErrors}
          confirmLabel={intl.formatMessage({ id: 'dashboard.account.details.save' })}
          cancelLabel={intl.formatMessage({ id: 'dashboard.account.details.cancel' })}
          onConfirm={(e) => {
            e.persist();
            this.setState({ errors: { duplicateAccount: null } },
              () => this.handleSubmit(e, true));
          }}
          onCancel={() => { this.setState({ errors: { duplicateAccount: null } }); }}
        >
          <Paragraph mb="l">
            <FormattedMessage id="dashboard.account.warning.duplicateAccount" />
          </Paragraph>
        </ConfirmationDialog>
        <ConfirmationDialog
          confirmButtonType="alert"
          show={domainNotInSubdomain && showGlobalErrors}
          confirmLabel={intl.formatMessage({ id: 'dashboard.account.details.save' })}
          cancelLabel={intl.formatMessage({ id: 'dashboard.account.details.cancel' })}
          onConfirm={(e) => {
            e.persist();
            this.setState({ errors: { domainNotInSubdomain: null } },
              () => this.handleSubmit(e, false, true));
          }}
          onCancel={() => { this.setState({ errors: { domainNotInSubdomain: null } }); }}
        >
          <Paragraph mb="l">
            <FormattedMessage id="dashboard.account.warning.domainChangedWithSubdomains" />
          </Paragraph>
        </ConfirmationDialog>
      </MyDataDetailsContainer>
    );
  }
}

export default injectIntl(AddEditAccountPage);
