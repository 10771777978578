import React from 'react';
import { object } from 'prop-types';

import DomainImage from 'pwm-components/components/DomainImage';
import AccountLink from './AccountLink';
import { isAttached } from '../../files/helpers/FileDataHelper';
import { getDomain } from '../../lib/DomainNameHelper';

const AccountLogo = React.memo(({ data, ...props }) => (
  <AccountLink
    data={data}
    {...props}
  >
    <DomainImage
      attached={isAttached(data.files)}
      domain={data ? getDomain(data.domain) ?? '' : ''}
      label={data ? data.label : ''}
      storage={localStorage}
    />
  </AccountLink>
));

AccountLogo.propTypes = {
  data: object.isRequired,
};

AccountLogo.displayName = 'AccountLogo';

export default AccountLogo;
