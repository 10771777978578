import UserAgentParser from 'mixpanel-lib/source/UserAgentParser';

import config from '../config';

const uaParser = new UserAgentParser(navigator.userAgent);

export function getBrowser() {
  return uaParser.getBrowser();
}

export function getEngine() {
  try {
    return uaParser._parsedUa.engine.name;
  } catch (e) {
    return null;
  }
}

export function getExtensionBrowserName() {
  const browser = getBrowser().toLowerCase();
  if (config.extension[browser]) {
    return browser;
  }

  const engine = getEngine();

  if (engine === 'Blink') {
    return 'chrome';
  }

  return browser;
}

export function getOS() {
  return uaParser.getOS();
}

export function getBuildVersion() {
  return uaParser.getBuildVersion();
}

export function getMajorVersion() {
  return uaParser.getMajorVersion();
}

export function isMobilePlatform() {
  const os = getOS().toLowerCase();
  return os === 'android' || os === 'ios';
}

export function isInternetExplorer() {
  return getBrowser().toLowerCase() === 'internet explorer';
}

export function isChrome() {
  return getBrowser() === 'Chrome';
}

export function isSafari() {
  return getBrowser() === 'Safari';
}

export function isFirefox() {
  return getBrowser() === 'Firefox';
}
