import { createSelector } from 'reselect';
import {
  FILTER_ALL, filterHelper, FILTER_FAVORITES_ACCOUNT, FILTER_FAVORITES_DEFAULT,
} from './MyDataHelper';
import { filterFiles } from '../files/helpers/FileDataHelper';


export const createTagHeaderSelector = (type, selector, favoriteKey) => createSelector(
  state => state[type],
  selector,
  (entities, counts) => {
    const ids = Object.keys(entities);
    const tags = [];

    let customTags = [];
    if (Object.keys(counts).length > 0) {
      customTags = Object.entries(counts)
        .map(([tag, count]) => ({
          id: tag,
          text: tag,
          filterCount: count,
          filter: data => (Array.isArray(data.tags) && data.tags.indexOf(tag) >= 0),
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    }

    const favoriteFilterFn = data => !!data[favoriteKey];
    const favoriteCount = ids.filter(filterHelper(entities, '', favoriteFilterFn)).length;

    if (favoriteCount > 0) {
      tags.push({
        id: favoriteKey,
        label: 'dashboard.accounts.showFavorites',
        icon: 'favorites-outline',
        filterCount: favoriteCount,
        filter: favoriteFilterFn,
      });
    }

    if (favoriteCount > 0 || customTags.length > 0) {
      tags.push({
        id: FILTER_ALL,
        label: 'dashboard.accounts.showAll',
        filterCount: ids.length,
      });
    }

    return tags.concat(customTags);
  },
);

export const createTagCountsSelector = type => createSelector(
  state => state[type],
  (entities) => {
    const counts = {};

    Object.values(entities).forEach(({ tags }) => {
      if (tags && tags.length > 0) {
        tags.forEach((tag) => {
          counts[tag] = (counts[tag] || 0) + 1;
        });
      }
    });

    return counts;
  },
);

export const createTagListSelector = selector => (
  createSelector(selector, counts => Object.keys(counts))
);


export const accountTagCountsSelector = createTagCountsSelector('accounts');
export const accountTagHeaderSelector = createTagHeaderSelector('accounts', accountTagCountsSelector, FILTER_FAVORITES_ACCOUNT);

export const noteTagCountsSelector = createTagCountsSelector('notes');
export const noteTagHeaderSelector = createTagHeaderSelector('notes', noteTagCountsSelector, FILTER_FAVORITES_DEFAULT);

export const creditCardsTagCountsSelector = createTagCountsSelector('creditCards');
export const creditCardsTagHeaderSelector = createTagHeaderSelector('creditCards', creditCardsTagCountsSelector, FILTER_FAVORITES_DEFAULT);

export const createFileListSelector = type => createSelector(
  state => state[type],
  state => state.files,
  (entities, files) => {
    Object.keys(entities).forEach(entityID => (
      Object.assign(entities[entityID], {
        files: filterFiles(files, entityID),
      })
    ));

    return entities;
  },
);

export const notesFileListSelector = createFileListSelector('notes');
export const accountsFileListSelector = createFileListSelector('accounts');
export const walletFileListSelector = createFileListSelector('creditCards');
