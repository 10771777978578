/* eslint-disable react/destructuring-assignment, complexity */
import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ms from 'ms';
import {
  checkIfUserHasLocalData,
  connectCloudSyncAdapter,
  connectNotificationServer,
  connectSync,
  disconnectCloudSyncAdapter,
  disconnectNotificationServer,
  disconnectSync,
  triggerSync,
  connectExtensionAdapter,
  disconnectExtensionAdapter,
  initializeSync,
  connectAsyncStorageAdapter,
  configureSyncModelsToMatchExtension,
  setError as setErrorAction,
} from '../DashboardActions';
import { getOEUserData } from '../../oe/OEActions';
import {
  getUserData, setSyncStore, updateEncryptedKey,
} from '../../user/UserActions';
import Loading from '../../app/components/Loading';
import config from '../../config';
import { trackTimeout } from '../../tracking/TrackingActions';

import { logoutUser } from '../../authentication/AuthenticationActions';

const LOGOUT_CONTEXT = 'apiConnector';
const LOGOUT_CAUSE = 'logout notification';

const LOCK_CONTEXT = 'apiConnector';
const LOCK_CAUSE = 'lock notification';

function promiseTimeout(promise, timeout, timeoutVal) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      if (typeof timeoutVal === 'undefined') {
        reject();
      } else {
        resolve(timeoutVal);
      }
    }, timeout);

    promise.then((...args) => {
      clearTimeout(timeoutId);
      resolve(...args);
    });
  });
}

class ApiConnector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      connected: false,
    };

    this.onLogoutNotification = this.onLogoutNotification.bind(this);
    this.onLockNotification = this.onLockNotification.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { dashboard: { isUnregisteredMode } } = this.props;
    if (!isUnregisteredMode) {
      await Promise.all([
        this.props.getOEUserData(), // TODO: check if it's needed here
        this.props.getUserData(),
      ]);
    }

    let userHasData = false;

    try {
      userHasData = await this.props.checkIfUserHasLocalData();
    } catch (error) {
      this.setError(error);
      throw error;
    }

    this.setState({ connected: true }, async () => {
      try {
        if (isUnregisteredMode) {
          await this.setAdaptersState(this.props);
          await this.props.initializeSync();
          this.setState({ loaded: true });
        } else {
          const adapterPromise = this.setAdaptersState(this.props)
            .catch((error) => {
              this.setError(error);
              throw error;
            });

          if (!userHasData) {
            await adapterPromise;
          }

          this.connectionPromise = this.connectApi();
          this.connectionPromise.then(async () => {
            this.connectionPromise = null;
            this.setState({ loaded: true });
          }).catch((error) => {
            this.setError(error);
            throw error;
          });
        }
      } catch (error) {
        this.setError(error);
        throw error;
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.loaded) {
      return;
    }

    if (nextProps.user.key !== this.props.user.key
      || nextProps.user.authToken !== this.props.user.authToken) {
      this.disconnectApi();
    }

    if (nextProps.user.key !== this.props.user.key && nextProps.user.key) {
      if (!this.state.connected) {
        this.connectApi();
      }
    }

    if (!this.relevantStateChange(nextProps)) {
      return;
    }

    this.setAdaptersState(nextProps);
  }

  componentWillUnmount() {
    if (this.connectionPromise) {
      this.connectionPromise.finally(() => {
        this.disconnectApi();
      });
    } else {
      this.disconnectApi();
    }
  }

  onLogoutNotification() {
    this.props.logoutUser(LOGOUT_CONTEXT, LOGOUT_CAUSE);
  }

  onLockNotification() {
    this.props.logoutUser(LOCK_CONTEXT, LOCK_CAUSE);
  }

  setError(error) {
    const { setError } = this.props;
    setError({ context: 'ApiConnector', error });
    Sentry.withScope((scope) => {
      scope.setExtra('context', 'ApiConnector');
      Sentry.captureException(error);
    });
  }

  async setAdaptersState(props) {
    const { dashboard, userData } = props;

    const isExtensionDependant = dashboard.isUnregisteredMode
      || userData.sync_store_manager !== config.sync.manager;

    const shouldBypassSyncModels = !dashboard.extensionCompatible && isExtensionDependant;

    const shouldConnectExtensionAdapter = dashboard.extensionConnected
      && dashboard.extensionCompatible;

    const shouldConnectSyncAdapters = userData.sync_store_manager === config.sync.manager
      && !shouldConnectExtensionAdapter;

    if (shouldConnectExtensionAdapter) {
      if (!dashboard.extensionAdapterConnected) {
        await this.props.connectExtensionAdapter();
      }
    } else {
      await this.props.disconnectExtensionAdapter();
    }

    if (shouldConnectSyncAdapters) {
      if (!dashboard.cloudSyncAdapterConnected) {
        await this.props.connectCloudSyncAdapter();
      }
    } else {
      await this.props.disconnectCloudSyncAdapter();
    }

    if (config.spotlight && dashboard.isUnregisteredMode) {
      await this.props.connectAsyncStorageAdapter();
    }

    if (shouldBypassSyncModels) {
      this.props.configureSyncModelsToMatchExtension();
    }
  }

  relevantStateChange(nextProps) {
    if (this.props.dashboard.extensionCompatible !== nextProps.dashboard.extensionCompatible) {
      return true;
    }

    if (this.props.userData.sync_store_manager !== nextProps.userData.sync_store_manager) {
      return true;
    }

    if (this.props.dashboard.isUnregisteredMode !== nextProps.dashboard.isUnregisteredMode) {
      return true;
    }

    return false;
  }

  async connectApi() {
    const notificationServer = config.localBuild
      ? null
      : await promiseTimeout(this.props.connectNotificationServer(), ms('5s'), null);
    await this.props.connectSync();

    if (!notificationServer) {
      return;
    }

    notificationServer.on('sync', this.props.triggerSync);
    notificationServer.on('syncChange', async (status) => {
      this.props.setSyncStore(status === 'enabled' ? config.sync.manager : status);
    });
    notificationServer.on('logout', this.onLogoutNotification);
    notificationServer.on('lock', this.onLockNotification);
    notificationServer.on('keyChanged', this.props.updateEncryptedKey);
  }

  disconnectApi() {
    this.props.disconnectNotificationServer();
    this.props.disconnectSync();
  }

  render() {
    if (this.state.loaded) {
      return this.props.children;
    }

    return (
      <Loading
        onRetry={() => window.location.reload()}
        onTimeout={() => this.props.onTimeout('ApiConnector')}
      >
        <FormattedMessage
          id="dashboard.accounts.loading"
        />
      </Loading>
    );
  }
}

ApiConnector.propTypes = {
  children: PropTypes.element,
  checkIfUserHasLocalData: PropTypes.func.isRequired,
  connectCloudSyncAdapter: PropTypes.func.isRequired,
  connectNotificationServer: PropTypes.func.isRequired,
  connectAsyncStorageAdapter: PropTypes.func.isRequired,
  configureSyncModelsToMatchExtension: PropTypes.func.isRequired,
  connectSync: PropTypes.func.isRequired,
  disconnectCloudSyncAdapter: PropTypes.func.isRequired,
  disconnectNotificationServer: PropTypes.func.isRequired,
  disconnectSync: PropTypes.func.isRequired,
  onTimeout: PropTypes.func.isRequired,
  getOEUserData: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
  triggerSync: PropTypes.func.isRequired,
  connectExtensionAdapter: PropTypes.func.isRequired,
  disconnectExtensionAdapter: PropTypes.func.isRequired,
  user: PropTypes.shape({
    authToken: PropTypes.string,
    key: PropTypes.string,
  }),
  userData: PropTypes.shape({
    sync_store_manager: PropTypes.string,
    verify_key: PropTypes.string,
  }).isRequired,
  dashboard: PropTypes.shape({
    extensionCompatible: PropTypes.bool,
    extensionConnected: PropTypes.bool,
    extensionIsOutdated: PropTypes.bool,
    extensionAdapterConnected: PropTypes.bool,
    cloudSyncAdapterConnected: PropTypes.bool,
    isUnregisteredMode: PropTypes.bool,
  }).isRequired,
  logoutUser: PropTypes.func.isRequired,
  updateEncryptedKey: PropTypes.func.isRequired,
  setSyncStore: PropTypes.func.isRequired,
  initializeSync: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

ApiConnector.defaultProps = {
  children: <div />,
  user: {
    authToken: null,
    key: null,
  },
};

const mapStateToProps = ({
  oe, user, userData, dashboard,
}) => ({
  oe, user, userData, dashboard,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  checkIfUserHasLocalData,
  connectCloudSyncAdapter,
  connectNotificationServer,
  configureSyncModelsToMatchExtension,
  connectSync,
  connectAsyncStorageAdapter,
  disconnectCloudSyncAdapter,
  disconnectNotificationServer,
  disconnectSync,
  onTimeout: trackTimeout,
  getOEUserData,
  getUserData,
  setSyncStore,
  triggerSync,
  connectExtensionAdapter,
  disconnectExtensionAdapter,
  logoutUser,
  updateEncryptedKey,
  initializeSync,
  setError: setErrorAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApiConnector);
