import React from 'react';
import { func, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';

import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';
import PasswordInput from 'pwm-components/components/PasswordInput';

import Button from 'pwm-components/components/Button';
import FakeInputField from '../../componentLib/FakeInputField';
import Card from '../../componentLib/CardWrapper';
import TimeToHack from '../../accounts/components/TimeToHack';
import tabIndex from '../../lib/TabIndexConfig';
import { validatePasswordStrength } from '../../lib/AuthenticationValidator';
import AuthenticationHelper from '../../lib/AuthenticationHelper';

import checkMark from '../../img/checkmark_verified.png';
import checkMark2x from '../../img/checkmark_verified2x.png';
import PageContentWrapper from '../../componentLib/PageContentWrapper';
import PageTitle from '../../componentLib/PageTitle';
import intlShape from '../../lib/intlShape';

class ChangeMasterPassword extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = this.props;

    this.state = {
      error: null,
      showConfirmMPReuse: false,
      showSuccess: false,
      loading: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
    this.onBackClick = this.onBackClick.bind(this);
    this.getRevealPasswordAction = this.getRevealPasswordAction.bind(this);
    this.getFieldError = this.getFieldError.bind(this);
    this.toggleRevealPassword = this.toggleRevealPassword.bind(this);
    this.setPasswordStrength = debounce(this.setPasswordStrength.bind(this), 300);
    this.onChange = this.onChange.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onContinueClick = this.onContinueClick.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);

    this.authHelper = new AuthenticationHelper(intl);
  }

  componentDidMount() {
    if (this.oldPasswordRef) {
      this.oldPasswordRef.focus();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.successTimeout);
  }

  onSuccess() {
    const { history } = this.props;
    this.setTimeout = setTimeout(() => {
      this.setState({ showSuccess: false });
      history.push('/settings');
    }, 3000);
  }

  onBackClick(e) {
    e.preventDefault();
    const { history } = this.props;
    history.goBack();
  }

  onCancelClick() {
    this.setState({ showConfirmMPReuse: false });
  }

  async onContinueClick() {
    const { oldPassword, newPassword, loading } = this.state;

    if (loading) {
      return;
    }

    const { onContinueClick } = this.props;
    try {
      this.setState({ loading: true, showConfirmMPReuse: false });
      await onContinueClick(newPassword, oldPassword);
      this.setState({ showSuccess: true, showConfirmMPReuse: false, loading: false });
      this.onSuccess();
    } catch (e) {
      this.setState({ error: e, showConfirmMPReuse: false });
    }
  }

  onCloseClick() {
    this.setState({ showSuccess: false });
  }

  onChange(name, value) {
    this.setState({ [name]: value, error: null });

    if (name === 'newPassword') this.setPasswordStrength(value);
  }

  async onSubmit() {
    const {
      oldPassword, newPassword, confirmPassword, strength: { score },
    } = this.state;
    const { onSubmit } = this.props;
    this.setState({ loading: true });
    try {
      await onSubmit(oldPassword, newPassword, confirmPassword, score);
      this.setState({ showSuccess: true, loading: false });
      this.onSuccess();
    } catch (error) {
      this.setState({ error, loading: false, showConfirmMPReuse: !!error.showConfirmation }, () => {
        const errorFieldToFocus = `${error.field}Ref`;
        if (this[errorFieldToFocus]) this[errorFieldToFocus].focus();
      });
    }
  }

  getRevealPasswordAction(type) {
    const stateKey = `revealPassword${type}`;
    const stateValue = this.state[stateKey];
    return {
      icon: stateValue ? 'hide' : 'show',
      show: true,
      tabIndex: tabIndex.optionalActionFields,
      label: stateValue ? 'dashboard.account.details.hidePassword' : 'dashboard.account.details.showPassword',
      onClick: () => this.toggleRevealPassword(stateKey),
    };
  }

  getFieldError(field) {
    const { error } = this.state;
    const { intl } = this.props;
    if (!error || error.field !== field) return null;

    return intl.formatMessage({ id: error.messageId });
  }

  setPasswordStrength(password) {
    this.setState({
      strength: validatePasswordStrength(password),
    });
  }

  toggleRevealPassword(passwordToToggle) {
    this.setState(prevState => ({ [passwordToToggle]: !prevState[passwordToToggle] }));
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      oldPassword, newPassword, confirmPassword,
      strength, loading, showSuccess, showConfirmMPReuse, error,
    } = this.state;
    const { intl } = this.props;
    let feedback = null;

    if (strength && newPassword.length) {
      const ps = validatePasswordStrength(newPassword);
      feedback = this.authHelper.prepareList(ps.feedback);
    }

    const confirmPasswordError = this.getFieldError('confirmPassword');
    const newPasswordError = this.getFieldError('newPassword');
    const oldPasswordError = this.getFieldError('oldPassword');
    return (
      <PageContentWrapper>
        <Box mb="xxl">
          <PageTitle onBackClick={this.onBackClick}>
            <FormattedMessage id="dashboard.settings.accountSettings.changeMasterPassword" />
          </PageTitle>
        </Box>
        <Card variant="secondary" p="xxl">
          <Paragraph size="large" mb="xxl">
            <FormattedMessage id="dashboard.settings.changeMasterPassword.header" />
          </Paragraph>
          <Box width="50%" m="0 auto">
            <Box mb="xxl">
              <FakeInputField type="password" />
              <PasswordInput
                id="a-old-master-password"
                ref={this.oldPasswordRef}
                tabIndex={tabIndex.mainFormFields}
                value={oldPassword}
                label={intl.formatMessage({ id: 'dashboard.settings.changeMasterPassword.old' })}
                error={oldPasswordError
                  ? <span dangerouslySetInnerHTML={{ __html: oldPasswordError }} />
                  : null}
                onChange={e => this.onChange('oldPassword', e.target.value)}
              />
            </Box>
            <Box mb="xxl">
              <FakeInputField type="password" />
              <PasswordInput
                id="a-master-password"
                tabIndex={tabIndex.mainFormFields}
                value={newPassword}
                score={strength && strength.score}
                feedback={feedback}
                label={intl.formatMessage({ id: 'dashboard.settings.changeMasterPassword.new' })}
                error={newPasswordError
                  ? <span dangerouslySetInnerHTML={{ __html: newPasswordError }} />
                  : null}
                onChange={e => this.onChange('newPassword', e.target.value)}
              />
            </Box>
            <Box mb="xxl">
              <FakeInputField type="password" />
              <PasswordInput
                id="a-master-password-confirm"
                tabIndex={tabIndex.mainFormFields}
                value={confirmPassword}
                label={intl.formatMessage({ id: 'dashboard.settings.changeMasterPassword.confirm' })}
                error={confirmPasswordError
                  ? <span dangerouslySetInnerHTML={{ __html: confirmPasswordError }} />
                  : null}
                onChange={e => this.onChange('confirmPassword', e.target.value)}
              />
            </Box>
            {strength && (
              <Box mb="xxl">
                <TimeToHack
                  score={strength.score}
                  time={strength.timeToCrack}
                />
              </Box>
            )}
            <Button
              id="a-change-master-password-button"
              fullWidth
              block
              loading={loading}
              onClick={this.onSubmit}
              tabIndex={tabIndex.mainFormFields}
              disabled={!(oldPassword && newPassword && confirmPassword) || loading}
            >
              <FormattedMessage id="dashboard.settings.accountSettings.changeMasterPassword" />
            </Button>
          </Box>
        </Card>
        <ConfirmationDialog
          show={showSuccess}
          onCloseClick={this.onCloseClick}
        >
          <Box>
            <Paragraph mb="l" textAlign="center">
              <img
                src={checkMark}
                srcSet={`${checkMark} 1x, ${checkMark2x} 2x`}
                alt="completed"
              />
            </Paragraph>
            <Paragraph size="h2" textAlign="center">
              <FormattedMessage id="dashboard.settings.changeMasterPassword.success" />
            </Paragraph>
          </Box>
        </ConfirmationDialog>
        {error && error.reusedPasswordDomains && (
          <ConfirmationDialog
            show={showConfirmMPReuse}
            cancelButtonType="primary"
            confirmButtonType="ghost"
            cancelLabel={
              intl.formatMessage({ id: 'dashboard.settings.changeMasterPassword.error.reused.cancel' })
            }
            confirmLabel={
              intl.formatMessage({ id: 'dashboard.settings.changeMasterPassword.error.reused.continue' })
            }
            onCancel={this.onCancelClick}
            onConfirm={this.onContinueClick}
          >
            <Box mb="l">
              <Paragraph>
                <FormattedMessage id="dashboard.settings.changeMasterPassword.error.reused.header" />
              </Paragraph>
              <ul className="c-change-mp__reused-list">
                {error.reusedPasswordDomains.map(domain => <li key={domain}>{domain}</li>)}
              </ul>
              <Paragraph>
                <FormattedMessage id="dashboard.settings.changeMasterPassword.error.reused.footer" />
              </Paragraph>
            </Box>
          </ConfirmationDialog>
        )}
      </PageContentWrapper>
    );
  }
}

ChangeMasterPassword.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onContinueClick: func.isRequired,
  history: object,
};

ChangeMasterPassword.defaultProps = {
  history: () => { },
};

export default withRouter(injectIntl(ChangeMasterPassword));
