import React from 'react';
import ConfigRenderer, { Props as ConfigRendererProps } from './ConfigRenderer';

export type Props = {
  wrapper: React.ComponentType;
} & ConfigRendererProps;

const ConfigConditionalWrapper: React.FC<Props> = ({
  children, wrapper: Wrapper, negate, ...props
}) => (
  <>
    <ConfigRenderer {...props} negate={negate}>
      <Wrapper>{children}</Wrapper>
    </ConfigRenderer>
    <ConfigRenderer {...props} negate={!negate}>
      { children }
    </ConfigRenderer>
  </>
);

export default ConfigConditionalWrapper;
