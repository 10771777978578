import { State, Entity } from '@avira-pwm/redux/accounts';
import { emailRegex } from './AuthenticationValidator';
import { isValidURL, getDomain } from './DomainNameHelper';


export const id = [
  {
    type: 'required',
  },
];

type RuleT = {
  type: string;
  global?: () => boolean;
  custom?: () => boolean;
};

export const label = [
  {
    type: 'custom',
    custom: (value: string, account: Entity & {labelAutoFilled: true}) => {
      if (value || (account.labelAutoFilled && account.domain)) {
        return true;
      }
      return false;
    },
  },
];

export const domain = [
  {
    type: 'format',
    format: (value: string): boolean => isValidURL(value),
  },
];
export const password = [
  {
    type: 'required',
  },
];

export const email = [
  {
    type: 'format',
    format: emailRegex,
  },
];

const rules = {
  id,
  label,
  domain,
  password,
  email,
};

export default rules;

const isSameLabel = (account1: Entity, account2: Entity): boolean => (
  (account1.label || getDomain(account1.domain))
  === (account2.label || getDomain(account2.domain))
);

const isSameDomain = (account1: Entity, account2: Entity): boolean => (
  getDomain(account1.domain) === getDomain(account2.domain)
);

const isSameEmail = (account1: Entity, account2: Entity): boolean => (
  account1.email === account2.email
);

const isSameUsername = (account1: Entity, account2: Entity): boolean => (
  account1.username === account2.username
);

const isSameUser = (account1: Entity, account2: Entity): boolean => (
  (account1.username || account1.email) === (account2.username || account2.email)
);

const isSameId = (account1: Entity, account2: Entity): boolean => (
  account1.id === account2.id
);

export const isSameEmailUsername = (account1: Entity, account2: Entity): boolean => (
  (!!account1.email && isSameEmail(account1, account2))
  || (account1.username && isSameUsername(account1, account2))
  || (!account1.username && !account1.email)
);

const includesSubDomain = ({ domain: test }: Entity, { subdomain }: Entity): boolean => (
  !!subdomain
  && subdomain.some(elt => elt && elt.subdomain === test)
);

const isSameSubdomain = (
  currentAccount: Entity, verificationAccount: Entity,
): boolean => (
  verificationAccount.subdomain
    ? includesSubDomain(currentAccount, verificationAccount)
    : currentAccount.domain === verificationAccount.domain
);

export const isSamePassword = (account1: Entity, account2: Entity): boolean => (
  account1.password === account2.password
);

const isSameNotes = (account1: Entity, account2: Entity): boolean => {
  const bothEmpty = !(!!account1.notes || !!account2.notes);
  return (bothEmpty || (account1.notes === account2.notes));
};

const isDuplicateWithoutPasswordNote = (
  currentAccount: Entity, verificationAccount: Entity,
): boolean => (
  currentAccount.domain
    ? (
      isSameDomain(currentAccount, verificationAccount)
      && isSameSubdomain(currentAccount, verificationAccount)
      && (
        isSameUser(currentAccount, verificationAccount)
        || isSameEmailUsername(currentAccount, verificationAccount)
      )
    )
    : isSameLabel(currentAccount, verificationAccount)
);

export const checkDomainInSubDomains = ({ domain: test, subdomain }: Pick<Entity, 'domain' | 'subdomain'>): boolean => (
  !!subdomain
  && !!test
  && subdomain.some(elt => elt && elt.subdomain.includes(test) && elt.visible)
);

export const isDomainInSubdomains = ({ domain: test, subdomain }: Pick<Entity, 'domain' | 'subdomain'>): RuleT[] => [
  {
    type: 'global',
    global: () => checkDomainInSubDomains({ domain: test, subdomain }),
  },
];

export const generateDuplicateAccountRule = (
  currentAccount: Entity, accounts: State,
): RuleT[] => [
  {
    type: 'global',
    global: () => {
      const duplicateAccount = Object.values(accounts).some(account => (
        !isSameId(currentAccount, account)
        && isSameLabel(currentAccount, account)
        && isSameDomain(currentAccount, account)
        && isSameSubdomain(currentAccount, account)
        && isSameEmailUsername(currentAccount, account)
        && (currentAccount.password && isSamePassword(currentAccount, account))
      ));
      return !duplicateAccount;
    },
  },
];

export const checkDuplicate = (
  currentAccount: Entity, verificationAccount: Entity, checkPasswordAndNote = false,
): boolean => {
  if (isSameId(currentAccount, verificationAccount)) return false;

  const isDuplicate = isDuplicateWithoutPasswordNote(currentAccount, verificationAccount);

  if (checkPasswordAndNote) {
    return (
      isDuplicate
      && isSamePassword(currentAccount, verificationAccount)
      && isSameNotes(currentAccount, verificationAccount)
    );
  }

  return isDuplicate;
};

export const generateImportDuplicateAccountRule = (
  currentAccount: Entity, accounts: State,
): RuleT[] => [
  {
    type: 'global',
    global: () => !Object.keys(accounts)
      .find(key => checkDuplicate(currentAccount, accounts[key], true)),
  },
  {
    type: 'custom',
    custom: () => !Object.keys(accounts)
      .find(key => checkDuplicate(currentAccount, accounts[key])),
  },
];
