import countBy from 'lodash/countBy';
import mapValues from 'lodash/mapValues';
import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import { validatePasswordStrength, validateEmail } from '../lib/AuthenticationValidator';
import { getDomain, getProtocol } from '../lib/DomainNameHelper';
import {
  isSecurityStatusIgnored, securityScoreMap, AUC_BAD_CATEGORIES,
  validateBreach, exemptedUnknownUsernames,
} from '../lib/SecurityStatusHelper';
import { getSuggestedUsernames, getUsernamesToSkip } from '../componentLib/selectors';
import { checkHibpUsernames } from '../user/selectors';

const calculateAccountSecurityScore = securityStatus => Math.max(Object.entries(securityScoreMap)
  .reduce((score, [key, value]) => {
    switch (key) {
      case 'default':
        return score + value;
      case 'password':
        return score - value[securityStatus.passwordScore];
      default:
        return securityStatus[key] ? score - value : score;
    }
  }, 0), 0);

const localhosts = ['localhost', '127.0.0.1', '0.0.0.0'];

const getProtocolSecurity = url => (!!url && getProtocol(url) !== 'https:' && !localhosts.includes(getDomain(url) || ''));

const checkUnsafeWebsite = (account) => {
  const insecureProtocol = getProtocolSecurity(account.login_url);
  return {
    insecureProtocol,
    unsafeWebsite: insecureProtocol,
  };
};

const getPasswordScore = (password) => {
  const passwordStrength = validatePasswordStrength(password);
  const passwordScore = passwordStrength ? passwordStrength.score : 3;
  const passwordTimeToCrack = passwordStrength ? passwordStrength.timeToCrack : 0;
  return ({ passwordScore, passwordTimeToCrack });
};

const getAviraBreached = (aviraBreachedWebsites, account, passwordsModifiedAt) => {
  const { domain, createdAt, passwordAutoGenerated } = account;
  if (domain && aviraBreachedWebsites[domain]) {
    const breach = aviraBreachedWebsites[domain].breaches[0];
    const breachCheck = validateBreach(new Date(breach.date), new Date(createdAt),
      new Date(passwordsModifiedAt[account.id] || createdAt), passwordAutoGenerated);
    if (breachCheck.breached) {
      return {
        aviraBreachedWebsite: true,
        aviraBreachedWebsiteDate: breach.date,
        aviraBreachedWebsiteId: breach.id,
      };
    }
  }
  return {
    aviraBreachedWebsite: false,
    aviraBreachedWebsiteDate: null,
    aviraBreachedWebsiteId: null,
  };
};

const getHibpWebsiteBreached = (hibpBreachedWebsites, account, passwordsModifiedAt) => {
  const { domain, createdAt, passwordAutoGenerated } = account;
  if (hibpBreachedWebsites && hibpBreachedWebsites[domain]) {
    const breach = hibpBreachedWebsites[domain][0];
    const breachCheck = validateBreach(
      new Date(breach.BreachDate),
      new Date(createdAt),
      new Date(passwordsModifiedAt[account.id] || createdAt),
      passwordAutoGenerated,
    );
    if (breachCheck.breached && breach.DataClasses && breach.DataClasses.includes('Passwords')) {
      return {
        hibpBreachedWebsite: true,
        hibpBreachedWebsiteDate: breach.BreachDate,
        hibpBreachedWebsiteId: breach.Name,
      };
    }
  }
  return {
    hibpBreachedWebsite: false,
    hibpBreachedWebsiteDate: null,
    hibpBreachedWebsiteId: null,
  };
};

const getAUCStatus = (aucData, account) => {
  const aucCategory = aucData[`https://${account.domain}/`] || null;
  return {
    auc: AUC_BAD_CATEGORIES.includes(aucCategory),
    aucCategory,
  };
};

const getHibpBreachedUsername = (account, hibpUsernames, passwordsModifiedAt) => {
  const currentUsernames = [];
  if (hibpUsernames[account.email]) {
    currentUsernames.push(account.email);
  }
  if (hibpUsernames[account.username]) {
    currentUsernames.push(account.username);
  }

  let breachState = {
    hibpBreachedAccountWarning: false,
    hibpBreachedAccountAlert: false,
    hibpBreachedAccountDate: null,
    hibpBreachedAccountId: null,
  };

  currentUsernames.forEach((username) => {
    const { breaches } = hibpUsernames[username];
    if (breaches) {
      const relevantBreach = breaches.find(breach => getDomain(breach.Domain) === account.domain);
      if (relevantBreach && relevantBreach.DataClasses && relevantBreach.DataClasses.includes('Passwords')) {
        const { breached, isWarning } = validateBreach(
          new Date(relevantBreach.BreachDate),
          new Date(account.createdAt),
          new Date(passwordsModifiedAt[account.id] || account.createdAt),
          account.passwordAutoGenerated,
        );

        if (breached) {
          breachState = {
            hibpBreachedAccountWarning: isWarning,
            hibpBreachedAccountAlert: !isWarning,
            hibpBreachedAccountDate: relevantBreach.BreachDate,
            hibpBreachedAccountId: relevantBreach.Name,
          };
        }
      }
    }
  });

  return breachState;
};

const getDependentBreached = (account, passwordsBreaches) => {
  const { password, domain } = account;
  if (passwordsBreaches && passwordsBreaches[password]
    && passwordsBreaches[password].domain !== domain) {
    const {
      breachId, domain: breachDomain, type, date,
    } = passwordsBreaches[password];
    return {
      dependentBreachWarning: true,
      dependentBreachId: breachId,
      dependentBreachDate: date,
      dependentBreachDomain: breachDomain,
      dependentBreachType: type,
    };
  }
  return {
    dependentBreachWarning: false,
    dependentBreachId: null,
    dependentBreachDate: null,
    dependentBreachDomain: null,
    dependentBreachType: null,
  };
};

export const getPasswordsBreaches = createSelector(
  state => state.passwordsBreaches,
  passwordsBreaches => Object.values(passwordsBreaches).reduce((p, passwordBreach) => {
    const { password } = passwordBreach;
    return password === '' ? p : { ...p, [password]: passwordBreach };
  }, {}),
);

export const getReusedPasswords = createSelector(
  state => state.accounts,
  accounts => mapValues(countBy(filter(accounts, 'password'), 'password'), count => count),
);

export const getUnknownAccounts = createSelector(
  state => state.accounts,
  state => state.hibp.usernames,
  state => state.deletedUnknownBreaches,
  getSuggestedUsernames,
  // eslint-disable-next-line max-params
  (accounts, hibpUsernames, deletedUnknownBreaches, suggestedUsernames) => {
    const unknownBreaches = {};
    const getKey = (domain, name) => `${domain}_${name}`;
    const accountsHash = Object.values(accounts).reduce((hash, { domain, username, email }) => {
      const newHash = {};
      if (username) newHash[getKey(domain, username)] = true;
      if (email) newHash[getKey(domain, email)] = true;
      return { ...hash, ...newHash };
    }, {});

    Object.keys(hibpUsernames).forEach((name) => {
      if (!validateEmail(name.toLowerCase())
        || exemptedUnknownUsernames.includes(name)
        || !suggestedUsernames.includes(name)) return;

      const { breaches } = hibpUsernames[name];
      if (breaches) {
        breaches.forEach((breach) => {
          if (breach.Domain) {
            const domain = getDomain(breach.Domain);
            const key = getKey(domain, name);
            const accountInPwm = accountsHash[key];
            if (!deletedUnknownBreaches[key] && (!accountInPwm
              || (accountInPwm.username
                && accountInPwm.username !== name)
              || (accountInPwm.email
                && accountInPwm.email !== name)
            )) {
              unknownBreaches[`${breach.Name}-${name}`] = {
                id: breach.Name,
                breachKey: key,
                fakeAccount: true,
                password: '',
                passwordScore: 3,
                username: name,
                domain,
                breachedAlert: true,
                hibpBreachedAccountAlert: true,
                hibpBreachedAccountDate: breach.BreachDate,
                loading: false,
              };
            }
          }
        });
      }
    });

    return unknownBreaches;
  },
);

export const getAccountSecurityStatus = createSelector(
  state => state.accounts,
  state => state.accountsModifiedAt,
  getReusedPasswords,
  state => state.aviraBreaches,
  state => state.hibp,
  state => state.hibp.usernames,
  state => state.auc,
  checkHibpUsernames,
  getUnknownAccounts,
  getPasswordsBreaches,
  getUsernamesToSkip,
  (
    accounts,
    accountsModifiedAt,
    reusedPasswords,
    aviraBreaches,
    { passwords, breachedWebsites, stopHibpPolling },
    hibpUsernames,
    aucData,
    breachCheck,
    unknownAccounts,
    passwordsBreaches,
    usernamesToSkip,
    // eslint-disable-next-line max-params
  ) => {
    const result = { ...unknownAccounts };
    // eslint-disable-next-line complexity
    Object.keys(accounts).forEach((id) => {
      const account = accounts[id];
      const { password, username, email } = account;
      const hibpBreachedPassword = !!(passwords[password] && passwords[password].isBreached);
      const { passwordScore, passwordTimeToCrack } = getPasswordScore(password);
      const reused = reusedPasswords[password] > 1;
      const reusedCount = reusedPasswords[password];
      const status = {
        ...account,
        passwordScore,
        passwordTimeToCrack,
        passwordStrength: securityScoreMap.password[passwordScore] !== 0,
        reused,
        reusedCount,
        ...checkUnsafeWebsite(account),
        ...getAviraBreached(aviraBreaches, account, accountsModifiedAt.passwords),
        ...getHibpWebsiteBreached(breachedWebsites, account, accountsModifiedAt.passwords),
        ...getAUCStatus(aucData, account),
        ...getHibpBreachedUsername(account, hibpUsernames, accountsModifiedAt.passwords),
        ...getDependentBreached(account, passwordsBreaches),
        hibpBreachedPassword,
        isDemoAccountModifiedAt: accountsModifiedAt.isDemoAccount
          ? accountsModifiedAt.isDemoAccount[id] : null,
        loading: !!breachCheck && !stopHibpPolling && !usernamesToSkip[username]
          && !!((email && !hibpUsernames[email]) || (username && !hibpUsernames[username])),
      };
      status.unsafeWebsiteWarning = status.insecureProtocol;
      status.unsafeWebsiteAlert = status.auc;
      status.unsafeWebsite = status.unsafeWebsiteWarning || status.unsafeWebsiteAlert;

      status.breachedWarning = status.aviraBreachedWebsite || status.hibpBreachedWebsite
        || status.hibpBreachedAccountWarning || status.dependentBreachWarning;
      status.breachedAlert = status.hibpBreachedPassword || status.hibpBreachedAccountAlert;
      status.breached = status.breachedWarning || status.breachedAlert;

      status.securityScore = calculateAccountSecurityScore(status);
      status.toImprove = status.securityScore < 100;
      status.ignoreWarnings = isSecurityStatusIgnored(account, status);

      result[id] = status;
    });
    return result;
  },
);
