import * as Sentry from '@sentry/browser';
import throttle from 'lodash/throttle';
import ms from 'ms';

import { clearUserPreferences } from '@avira-pwm/redux/userPreferences';
import responseCodes from '@avira-pwm/services/responseCodes';
import { sha256 } from '@avira-pwm/crypto-tools';
import debug from '../debug';

import {
  AUTH_NEW_TIMESTAMP,
  AUTH_SET_TIMESTAMP,
  AUTH_CLEAR,
  AUTH_EMAIL,
  AUTH_SET_DID_RELOAD,
} from './AuthActionTypes';

import {
  logoutOEUser,
  oeStoreToken,
  getOEUserData,
  setPaymentUrl,
  setAnonymousOEUser,
  setupUnregisteredMode,
  deactivateSpotlightUnregisteredMode,
} from '../oe/OEActions';

import {
  userLogout,
  storeAuthToken,
  clearAuthToken,
  storeKey,
  clearKey as clearAviraKey,
  getUserData,
  setLockReason,
  clearUnregisteredKeys,
} from '../user/UserActions';
import { getRelevantUserId } from '../user/selectors';

import { trackEvent } from '../tracking/TrackingActions';
import { MP_LOGOUT, MP_LOCK } from '../tracking/MixpanelEvents';
import { activateUnregisteredMode, deactivateUnregisteredMode, setError } from '../dashboard/DashboardActions';
import { UPDATE_USER_DATA } from '../user/UserActionTypes';
import { mapErrorCode, isNetworkError, isOETimeoutError } from '../lib/ErrorHelper';
import * as SpotlightAPI from '../lib/SpotlightAPI';
import config from '../config';
import {
  storeKeys as storeNLOKKeys,
  clearKeys as clearVaultKeys,
  clearMigrationAttempts,
  closeVault,
} from '../nlok/NLOKActions';
import { isExtensionCompatibleWithVaultSDK } from '../nlok/helpers';

const throttleEmailUpdate = throttle((dispatch, email) => dispatch({ type: AUTH_EMAIL, email }));

export const authUpdateEmail = email => dispatch => throttleEmailUpdate(dispatch, email);

export const authClear = () => ({ type: AUTH_CLEAR });
export const authNewTimestamp = () => ({ type: AUTH_NEW_TIMESTAMP });
export const authSetTimestamp = timestamp => ({ type: AUTH_SET_TIMESTAMP, timestamp });
export const authSetDidReload = (didReload = false) => ({ type: AUTH_SET_DID_RELOAD, didReload });

const logger = debug.extend('AuthenticationActions');

// eslint-disable-next-line complexity
export const trackLogout = (context, cause, error) => async (dispatch, getState) => {
  const {
    oe: {
      token,
      refreshToken,
      expiresIn,
      expiresAt,
    },
    tracking: { localSalt },
  } = getState();

  let daysSinceOERefresh = null;

  if (expiresAt != null && expiresIn != null) {
    const now = Date.now();
    const fetchTimestamp = expiresAt - expiresIn;

    // as it's a ceil, the ammount of days that represent an expired token
    // would be 12 for an access token (valid for 11 days)
    // and 61 days for a refresh token (valid for 60 days)
    daysSinceOERefresh = Math.ceil((now - fetchTimestamp) / ms('1d'));
  }

  dispatch(trackEvent(MP_LOGOUT, {
    context,
    cause,
    error,
    tokenHash: (error && token && localSalt)
      ? sha256(token + localSalt)
      : undefined,
    refreshTokenHash: (error && refreshToken && localSalt)
      ? sha256(refreshToken + localSalt)
      : undefined,
    daysSinceOERefresh,
  }));
};

export const logoutDevice = () => (dispatch, getState, { licenseService }) => {
  const { user: { authToken } } = getState();
  if (authToken) {
    licenseService.logOutDevice(authToken, authToken);
  }
};

const sendAuthDataToSpotlight = () => (
  /**
   * @param {any} dispatch
   * @param {() => import('../app/store').RootState} getState
   */
  (_dispatch, getState) => {
    if (SpotlightAPI.syncAuthData.isAvailable()) {
      const {
        oe, user, auth, dashboard, tracking, userData,
      } = getState();

      SpotlightAPI.syncAuthData({
        oeEmail: oe.email,
        oeToken: oe.token,
        oeUserId: null, // TODO: check where to get this
        oeRefreshToken: oe.refreshToken,
        oeExpiresAt: oe.expiresAt,
        oeExpiresIn: oe.expiresIn,
        tempOeToken: oe.unregisteredModeToken,
        tempOeRefreshToken: oe.unregisteredModeRefreshToken,
        tempOeExpiresAt: oe.unregisteredModeExpiresAt,
        tempOeExpiresIn: oe.unregisteredModeExpiresIn,
        pwmUserId: userData.id,
        pwmKey: user.key,
        pwmKey2: user.key2,
        pwmAuthToken: user.authToken,
        pwmLockReason: user.lockReason,
        tempPwmUserId: user.tempUserId,
        tempPwmKey: user.tempKey,
        timestamp: auth.timestamp,
        isUnregisteredMode: dashboard.isUnregisteredMode,
        distinctId: tracking.distinctId,
      });
    }
  }
);

const sendAuthDataToExtension = () => (
  /**
   * @param {any} _dispatch
   * @param {() => import('../app/store').RootState} getState
   * @param {import('../app/thunk').ThunkExtraArgument} thirdArgument
   */
  (_dispatch, getState, { dashboardMessenger }) => {
    const {
      oe,
      user,
      auth,
      tracking,
      nlokData,
    } = getState();

    const authData = {
      oe_token: oe.token,
      oeUserId: oe.id,
      email: oe.email,
      auth_token: user.authToken,
      key: user.key,
      key2: user.key2,
      timestamp: auth.timestamp,
      distinct_id: tracking.distinctId,
      refresh_token: oe.refreshToken,
      expires_at: oe.expiresAt,
      lockReason: user.lockReason,
      vaultEncryptionKey: nlokData.encryptionKey,
      vaultChallengeKey: nlokData.challengeKey,
    };

    dashboardMessenger.send('dashboard:extension:setAuthData', authData);
  }
);

/**
 * @param {object} param
 * @param {string | null} param.key
 * @param {string | null} param.key2
 * @param {string | null} param.authToken
 * @param {string | null} param.lockReason
 * @param {number | null} param.timestamp
 */
export const setAuthData = ({
  key,
  key2,
  authToken,
  lockReason,
  timestamp,
}) => (
  async (dispatch) => {
    await dispatch(storeAuthToken(authToken));
    dispatch(storeKey(key, key2));
    await dispatch(setLockReason(lockReason));
    await dispatch(authSetTimestamp(timestamp));
  }
);

/**
 *
 * @param {import('./components/ExtensionSync').ReceiveAuthData} authData
 */
export const setExtAuthData = authData => (
  /**
   *
   * @param {*} dispatch
   * @param {() => import('../app/store').RootState} getState
   * @returns
   */
  // eslint-disable-next-line complexity
  async (dispatch, getState) => {
    const {
      oe_token,
      oeUserId,
      email,
      expires_at,
      expires_in,
      refresh_token,
      auth_token,
      key,
      key2,
      timestamp: newOperationTimestamp,
      isUnregisteredMode,
      tempUserId,
      tempKey,
      user_id: userId,
      tempOeToken,
      tempOeRefreshToken,
      tempExpiresAt,
      tempExpiresIn,
      lockReason,
      vaultEncryptionKey,
      vaultChallengeKey,
    } = authData;

    const {
      auth: { timestamp: lastOperationTimestamp }, dashboard, userData: { id }, user,
    } = getState();
    if (userId && !id) {
      dispatch({ type: UPDATE_USER_DATA, data: { id: userId } });
    }

    if (Boolean(isUnregisteredMode) !== Boolean(dashboard.isUnregisteredMode)) {
      if (isUnregisteredMode) {
        dispatch(activateUnregisteredMode({ tempKey, tempUserId }));
        await dispatch(setAnonymousOEUser({
          tempOeToken,
          tempOeRefreshToken,
          tempExpiresAt,
          tempExpiresIn,
        }));
        await dispatch(setPaymentUrl());
      } else {
        dispatch(deactivateUnregisteredMode());
      }
    }

    if (!tempKey && !tempUserId) {
      dispatch(clearUnregisteredKeys());
    }

    if (newOperationTimestamp > Date.now()) {
      return;
    }

    if (lastOperationTimestamp <= Date.now() && lastOperationTimestamp > newOperationTimestamp) {
      return;
    }

    dispatch(oeStoreToken({
      email,
      token: oe_token,
      id: oeUserId,
      expiresAt: expires_at,
      expiresIn: expires_in,
      refreshToken: refresh_token,
    }));

    dispatch(setAuthData({
      key,
      key2: !key ? null : (key2 || user.key2),
      authToken: auth_token,
      lockReason,
      timestamp: newOperationTimestamp,
    }));

    if (
      typeof vaultEncryptionKey !== 'undefined'
      && typeof vaultChallengeKey !== 'undefined'
      && dashboard.extensionInstalledVersion != null
      && isExtensionCompatibleWithVaultSDK(dashboard.extensionInstalledVersion)
    ) {
      dispatch(storeNLOKKeys(
        vaultEncryptionKey,
        vaultChallengeKey,
      ));
    }
  }
);

/**
 * @param {import('@avira-pwm/helpers/Auth').AuthData} authData
 */
const tryMergeSpotlightAuthData = authData => (
  // eslint-disable-next-line complexity
  async (dispatch, getState) => {
    const {
      pwmKey: key,
      pwmUserId: userId,
      pwmLockReason: lockReason,
      timestamp: newOperationTimestamp,
      isUnregisteredMode,
      tempPwmKey: tempKey,
      tempPwmUserId: tempUserId,
    } = authData;

    const {
      auth: { timestamp: lastOperationTimestamp }, userData: { id }, oe,
    } = getState();
    if (newOperationTimestamp && newOperationTimestamp > Date.now()) {
      return;
    }

    if (
      lastOperationTimestamp <= Date.now()
      && lastOperationTimestamp > (newOperationTimestamp ?? 0)
    ) {
      return;
    }

    if (isUnregisteredMode && tempKey && tempUserId) {
      // if oe email is present .. it means the user logged in
      // so disable unregistered mode
      if (oe.email) {
        await dispatch(deactivateSpotlightUnregisteredMode());
      } else {
        await dispatch(setupUnregisteredMode(tempKey, tempUserId));
        await dispatch(authSetTimestamp(newOperationTimestamp));
      }
    } else if (userId === id) {
      await dispatch(storeKey(key));
      await dispatch(setLockReason(lockReason));
      await dispatch(authSetTimestamp(newOperationTimestamp));
    }
  }
);

export const setSpotlightAuthData = authData => (
  async (dispatch) => {
    await dispatch(tryMergeSpotlightAuthData(authData));
  }
);

export const getSpotlightAuthData = () => (
  async (dispatch, getState) => {
    if (!SpotlightAPI.getAuthData.isAvailable()) {
      return;
    }

    const userId = getRelevantUserId(getState());
    if (userId) {
      const spotlightAuthData = await new Promise((resolve) => {
        SpotlightAPI.getAuthData(userId.toString(), (err, data) => {
          if (err) {
            resolve(null);
          } else {
            resolve(data);
          }
        });
      });

      if (spotlightAuthData) {
        await dispatch(tryMergeSpotlightAuthData(spotlightAuthData));
      }
    }
  }
);

export const sendSync = (updateTimestamp = true) => (dispatch) => {
  if (updateTimestamp) {
    dispatch(authNewTimestamp());
  }

  if (config.spotlight) {
    dispatch(sendAuthDataToSpotlight());
  } else {
    dispatch(sendAuthDataToExtension());
  }
};

const captureBreadcrumb = message => Sentry.addBreadcrumb({ message });

const handleLicenseError = (error, context) => (
  // eslint-disable-next-line max-statements
  (dispatch) => {
    const errorCode = mapErrorCode(error);
    if (isNetworkError(error) || isOETimeoutError(error)) {
      dispatch(setError({ errorCode, context, error }));
      return;
    }

    const isOEError = [
      responseCodes.oe.INVALID_TOKEN,
      responseCodes.oe.EXPIRED_OE_TOKEN,
    ].includes(errorCode);

    const isTokenMistmatchError = [
      responseCodes.license.TOKEN_MISMATCH,
    ].includes(errorCode);

    if (isOEError) {
      logger('Auth actions: logging out', context, errorCode, error.message);
      const cause = 'failed to fetch user data';
      const mpError = errorCode || error.message;
      dispatch(trackLogout(context, cause, mpError));
      dispatch(logoutOEUser());
      dispatch(logoutDevice());
      dispatch(clearAuthToken());
    } else if (isTokenMistmatchError) {
      dispatch(logoutDevice());
      dispatch(clearAuthToken());
    } else {
      logger('Auth actions: locking', context, errorCode, error.message);
      dispatch(trackEvent(MP_LOCK, { context, cause: 'failed to fetch user data', error: errorCode || error.message }));
    }

    dispatch(clearAviraKey());
    dispatch(clearVaultKeys());
    dispatch(sendSync());
  }
);

const handleOEError = (error, context) => (dispatch) => {
  if (isNetworkError(error) || isOETimeoutError(error)) {
    dispatch(setError({ errorCode: mapErrorCode(error), context, error }));
    return;
  }

  const errorCode = mapErrorCode(error);
  logger('Auth actions: logging out', context, error.code, error.message);
  const cause = 'failed to fetch oe data';
  const mpError = errorCode || error.message;

  dispatch(trackLogout(context, cause, mpError));
  dispatch(logoutDevice());
  dispatch(logoutOEUser());
  dispatch(clearAuthToken());
  dispatch(clearAviraKey());
  dispatch(clearVaultKeys());
  dispatch(sendSync());
};

export const initAuth = isSpotlight => async (dispatch, getState) => {
  const logInitAuth = logger.extend('initAuth');
  const { user, oe } = getState();
  logInitAuth('start OEToken: %s, AuthToken: %s, Email: %s', oe.token, user.authToken, oe.email);
  if (oe.token && oe.email) {
    try {
      if (!isSpotlight) {
        logInitAuth('getOEUserData start');
        await dispatch(getOEUserData());
        logInitAuth('getOEUserData success');
        dispatch(setPaymentUrl()).catch(() => null);
      }

      captureBreadcrumb('Fetched OE');

      if (user.authToken) {
        try {
          logInitAuth('getUserData start');
          await dispatch(getUserData());
          logInitAuth('getUserData success');
          captureBreadcrumb('Fetched PWM');
        } catch (e) {
          logInitAuth('getUserData failed: %s', e.message);
          dispatch(handleLicenseError(e, 'AuthenticationActions:initAuth'));
        }
      }
    } catch (e) {
      dispatch(handleOEError(e, 'AuthenticationActions:initAuth'));
    }
  }
};

export const logoutUser = (context, cause, error) => async (dispatch) => {
  logger('Auth actions: logging out', context, cause, error);
  dispatch(trackLogout(context, cause, error));
  dispatch(clearUserPreferences());
  dispatch(logoutDevice());
  dispatch(userLogout());
  dispatch(clearVaultKeys());
  dispatch(closeVault());
  dispatch(clearMigrationAttempts());
  dispatch(logoutOEUser());
  dispatch(sendSync());
};
