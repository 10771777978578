/* eslint-disable complexity */
import Papa from 'papaparse';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import jschardet from 'jschardet';
import {
  addSubdomain,
  getMatchingAccounts,
  State as AccountsState,
  Entity as AccountEntity,
} from '@avira-pwm/redux/accounts';
import { sha256 } from '@avira-pwm/crypto-tools';
import ServerDate from '@avira-pwm/helpers/ServerDate';
import { SyncUtils, Sync } from '@avira-pwm/sync';
import { State as NotesState, Entity as NoteEntity } from '@avira-pwm/redux/notes';
import { Dispatch, Action } from 'redux';
import DashboardMessenger from '@avira-pwm/messenger/dashboard';
import nativeMsgCode from './nativeMessagingErrorCode';
import { TrackingActions, MixpanelEvents } from '../tracking';

import {
  IMPORTER_SELECT_ITEM,
  IMPORTER_SELECT_ITEMS,
  IMPORTER_DESELECT_ITEM,
  IMPORTER_DESELECT_ITEMS,
  IMPORTER_LOAD_ITEMS,
  IMPORTER_CLEAR_ITEMS,
  IMPORTER_SET_MAPPING,
  IMPORTER_FILL_ACCOUNTS_INFO,
  IMPORTER_SET_BROWSER_DATA,
  IMPORTER_FINAL_STATUS_INFO,
  IMPORTER_RESTORE_DEFAULT_DESELECTED,
} from './ImporterActionTypes';

import DataValidator from '../lib/DataValidator';
import { generateID } from '../lib/AccountHelper';
import { validateEmail } from '../lib/AuthenticationValidator';
import AccountValidatorRules, { generateImportDuplicateAccountRule, isSameEmailUsername, isSamePassword } from '../lib/AccountValidatorRules';
import NoteNameHelper from '../lib/NoteNameHelper';

import { Types as ImportItemType, IMPORT_ITEM_TYPE_ACCOUNT, IMPORT_ITEM_TYPE_NOTE } from './components/ImportItemTypes';
import {
  State as ImporterState, Mapping, RawData, ItemData, EntityData,
} from './ImporterReducer';
import { generateImportDuplicateNoteRule } from '../lib/NoteValidatorRules';
import { getRelevantUserKey } from '../user/selectors';
import { constructValidUrl, getDomain, getSubdomain } from '../lib/DomainNameHelper';
import getSyncInstance from '../lib/SyncInstanceHelper';
import { handleSyncError } from '../lib/ErrorHelper';

const { trackEvent } = TrackingActions;

const {
  MP_IMPORT_IMPORT_OPEN,
  MP_IMPORT_IMPORT_SOURCE_SELECTED,
  MP_IMPORT_IMPORT_MAPPED,
  MP_IMPORT_REVIEWED,
} = MixpanelEvents;

type AnyItemData = {
  label: string;
  domain: string;
  username: string;
  email: string;
  password: string;
  notes: string;
}

type SubdomainItemData = {
  id: string;
  subdomain: string;
  loginUrl: string;
}

type ItemDataAndError<T> = {
  errors: null | {};
  itemData: ItemData<T>;
}

type AccountItemDataAndError = ItemDataAndError<AccountEntity>
type NoteItemDataAndError = ItemDataAndError<NoteEntity>


type ItemHashes = {
  [K in string]: boolean;
}

export type State = {
  accounts: AccountsState;
  notes: NotesState;
  importer: ImporterState;
  preferences: { language: string };
}

const getFileEncoding = (file: Blob): Promise<string> => new Promise((resolve) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = (event) => {
    try {
      const string = String.fromCharCode.apply(
        null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        Array.prototype.slice.apply(new Uint8Array(event.target.result)),
      );

      const { encoding } = jschardet.detect(string);

      if (!encoding) {
        resolve('utf-8');
      } else {
        resolve(encoding);
      }
    } catch (e) {
      resolve('utf-8');
    }
  };
});

const getItemType = ({
  password, email, username, notes,
}: {
  password: string; email: string; username: string; notes: string;
}): ImportItemType => {
  if (password || email || username) {
    return IMPORT_ITEM_TYPE_ACCOUNT;
  } if (!password && notes) {
    return IMPORT_ITEM_TYPE_NOTE;
  }
  return IMPORT_ITEM_TYPE_ACCOUNT;
};

const getAccountItemData = (
  accountData: AnyItemData, accounts: AccountsState, itemsHashes: { [K: string]: boolean },
): AccountItemDataAndError => {
  const accountId = generateID();
  const accountHashes = itemsHashes;

  const accWithLabelAutoFilled = {
    id: accountId,
    labelAutoFilled: true,
    ...accountData,
  };

  let errors = DataValidator(accWithLabelAutoFilled, {
    ...AccountValidatorRules,
    duplicateAccount: generateImportDuplicateAccountRule(accWithLabelAutoFilled, accounts),
  });

  if (!errors) {
    const accHash = sha256(JSON.stringify(accountData));

    if (accountHashes[accHash]) {
      errors = {
        duplicateImport: true,
      };
    } else {
      accountHashes[accHash] = true;
    }
  }

  return {
    errors,
    itemData: {
      id: accountId,
      data: accountData,
    },
  };
};

const getNoteItemData = (data: AnyItemData, notes: NotesState): NoteItemDataAndError => {
  const noteId = generateID();

  const noteData = {
    title: data.label,
    notes: data.notes,
  };

  const noteObjToValidate = {
    id: noteId,
    ...noteData,
  };

  const errors = DataValidator(noteObjToValidate, {
    duplicateNote: generateImportDuplicateNoteRule(noteObjToValidate, notes),
  });

  return {
    errors,
    itemData: {
      id: noteId,
      data: noteData,
    },
  };
};

export const loadItems = (data: Papa.ParseResult): any => ({
  type: IMPORTER_LOAD_ITEMS, value: data,
});

export const readFile = (file: File) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  const { data } = await new Promise(async (resolve) => {
    const encoding = await getFileEncoding(file);
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: resolve,
      encoding,
    });
  });

  dispatch(loadItems(data));
  return data;
};

export const setBrowserData = (fetchedCredentials: any): any => (
  { type: IMPORTER_SET_BROWSER_DATA, value: fetchedCredentials }
);

export const checkNativeMessagingPermissions = () => async (
  dispatch: Dispatch,
  getState: {},
  { dashboardMessenger }: { dashboardMessenger: DashboardMessenger },
) => {
  await new Promise((resolve, reject) => {
    const waitForExtensionToAnswer = setTimeout(() => {
      dispatch(setBrowserData({
        errorCode: nativeMsgCode.noNativeMessagingCompatibleExt,
      }));
      reject(nativeMsgCode.noNativeMessagingCompatibleExt);
    }, 1000);


    dashboardMessenger.send(
      'dashboard:extension:checkNativeMessagingPermissions',
      null,
      (err, hasNativeMessagingPermission) => {
        clearTimeout(waitForExtensionToAnswer);
        if (!hasNativeMessagingPermission) {
          dispatch(setBrowserData({
            errorCode: nativeMsgCode.noNativeMessagingPermissions,
          }));
          reject(nativeMsgCode.noNativeMessagingPermissions);
        }
        resolve();
      },
    );
  });
};

export const requestNativeMessagingPermissions = () => async (
  dispatch: Dispatch,
  getState: {},
  { dashboardMessenger }: { dashboardMessenger: DashboardMessenger },
) => {
  await new Promise((resolve, reject) => {
    dashboardMessenger.send(
      'dashboard:extension:requestNativeMessagingPermissions',
      null,
      (err, permissionGranted) => {
        if (!permissionGranted) {
          reject();
        }
        resolve();
      },
    );
  });
};

export const requestCredentials = () => async (
  dispatch: Dispatch,
  getState: {},
  { dashboardMessenger }: { dashboardMessenger: DashboardMessenger },
) => {
  await new Promise((resolve, reject) => {
    dashboardMessenger.send(
      'dashboard:extension:fetchPasswordsFromNativePWM',
      null,
      (err, fetchedCredentials) => {
        if (!fetchedCredentials) {
          reject(fetchedCredentials);
        }
        dispatch(setBrowserData(fetchedCredentials));
        resolve();
      },
    );
  });
};

export const setMapping = (mapping: Mapping): any => ({
  type: IMPORTER_SET_MAPPING,
  value: mapping,
});

export const setItems = (
  items: ItemData<AccountEntity | NoteEntity>[],
  errors: Array<null|{}>,
  itemsType: ImportItemType[],
): any => (
  { type: IMPORTER_FILL_ACCOUNTS_INFO, value: { items, errors, itemsType } }
);
export const clearItems = (): Action => ({ type: IMPORTER_CLEAR_ITEMS });

export const selectItem = (index: number): any => ({
  type: IMPORTER_SELECT_ITEM, value: index,
});
export const selectItems = (indices: { [K in number]: boolean }): any => ({
  type: IMPORTER_SELECT_ITEMS, value: indices,
});
export const deselectItem = (index: number): any => ({
  type: IMPORTER_DESELECT_ITEM, value: index,
});
export const deselectItems = (indicies: number[]): any => ({
  type: IMPORTER_DESELECT_ITEMS, value: indicies,
});
export const restoreDefaultDeselected = (indices: number[]): any => ({
  type: IMPORTER_RESTORE_DEFAULT_DESELECTED, value: indices,
});

const getUsernameAndEmail = (username: string, secondaryUsername: string): [string, string] => {
  if (validateEmail(username)) {
    if (secondaryUsername && validateEmail(secondaryUsername)) {
      return [username, secondaryUsername];
    }
    return [secondaryUsername, username];
  }
  return [username, validateEmail(secondaryUsername) ? secondaryUsername : ''];
};

export const parseItems = () => (dispatch: Dispatch, getState: () => State) => {
  const { accounts, notes, importer } = getState();

  const itemsToDeselect: number[] = [];
  const errorsArray: ImporterState['errors'] = [];
  const items: ImporterState['items'] = [];
  const itemsHashes: ItemHashes = {};
  const itemsType: ImporterState['itemsType'] = [];

  const getValue = (item: RawData, key: keyof Mapping): string => {
    const index = importer.mapping[key];
    let value = '';
    if (typeof index === 'number' && typeof item[index] === 'string') {
      [value] = item[index].split('\n');
    } else if (Array.isArray(index)) {
      value = index.map(i => item[i]).filter(i => !!i).join('\n\n');
    }
    return value;
  };

  importer.rawData.forEach((item, index) => {
    const name = getValue(item, 'name');
    const website = getValue(item, 'website');
    const password = getValue(item, 'password');
    const note = getValue(item, 'notes');

    const [username, email] = getUsernameAndEmail(
      getValue(item, 'username'),
      getValue(item, 'secondaryUsername'),
    );

    const data = {
      label: name,
      domain: website || '',
      username,
      email,
      password,
      notes: note,
    };

    const itemType = getItemType(data);

    const { itemData, errors } = itemType === IMPORT_ITEM_TYPE_ACCOUNT
      ? getAccountItemData(data, accounts, itemsHashes) : getNoteItemData(data, notes);

    itemsType.push(itemType);

    if (errors) {
      itemsToDeselect.push(index);
    }

    errorsArray.push(errors);
    items.push(itemData);
  });

  dispatch(deselectItems(itemsToDeselect));
  dispatch(setItems(items, errorsArray, itemsType));
};

const isSameNotes = (currentAccount: AccountEntity, accountToImport: AccountEntity): boolean => (
  !accountToImport.notes || (currentAccount.notes === accountToImport.notes)
);

const isSameDomain = (currentAccount: AccountEntity, accountToImport: AccountEntity): boolean => (
  !accountToImport.domain
    || (getDomain(currentAccount.domain) === getDomain(accountToImport.domain))
);

const getMatch = (
  accounts: AccountEntity[],
  item: EntityData<AccountEntity>,
): AccountEntity | undefined => (
  accounts.find(account => (
    isSamePassword(account, item as AccountEntity)
    && isSameEmailUsername(account, item as AccountEntity)
    && isSameNotes(account, item as AccountEntity)
    && isSameDomain(account, item as AccountEntity)
  ))
);

const getToImportMatch = ({
  accountsToImport, item,
}: {
  accountsToImport: ItemData<AccountEntity>[]; item: ItemData<AccountEntity>['data'];
}): AccountEntity | undefined => {
  const accounts = accountsToImport.map(({ id, data }) => ({ id, ...data }));

  return getMatch(accounts, item);
};

const getExistingMatch = ({
  accounts, item, domain, subdomain,
}: {
  accounts: AccountsState; item: ItemData<AccountEntity>['data']; domain: string; subdomain: string;
}): AccountEntity | undefined => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore, max-len
  // @ts-ignore this is a weird bug on redux library that removes, for some reason, null from ignoreHibpBreachedPassword. Hopefully fixed after we migrate to TS 4
  const matches = getMatchingAccounts(accounts)(domain, subdomain).map(match => match.data);

  return getMatch(matches, item);
};

const getMatchingAccountId = ({
  accounts,
  accountsToImport,
  item,
  domain,
  subdomain,
}: {
  accounts: AccountsState;
  accountsToImport: ItemData<AccountEntity>[];
  item: ItemData<AccountEntity>;
  domain: string;
  subdomain: string;
}): string | null => {
  const match = getExistingMatch({
    accounts, item: item.data, domain, subdomain,
  }) || getToImportMatch({ accountsToImport, item: item.data });

  return match ? match.id : null;
};

type DataToImport = {
  notes: ItemData<NoteEntity>[];
  accounts: ItemData<AccountEntity>[];
  subdomains: SubdomainItemData[];
  mergeCount: number;
}

export const prepareDataToImport = ({
  accounts,
  notes,
  preferences: { language },
  importer: { items, itemsType, deselected },
}: State): DataToImport => {
  const dataToImport: DataToImport = {
    notes: [],
    accounts: [],
    subdomains: [],
    mergeCount: 0,
  };

  const newNotes: NotesState = {};
  // eslint-disable-next-line max-statements
  items.forEach((item, i) => {
    const itemType = itemsType[i];
    const { data: itemData } = item;

    if (item && deselected.indexOf(i) === -1) {
      if (itemType === IMPORT_ITEM_TYPE_ACCOUNT) {
        const accountItemData = itemData as ItemData<AccountEntity>['data'];
        const url = accountItemData.domain as string;
        const domain = getDomain(url);
        const subdomain = getSubdomain(url);

        if (domain && subdomain && domain !== subdomain) {
          const loginUrl = constructValidUrl(url) as string;
          const subdomainEntry = {
            id: item.id,
            subdomain,
            loginUrl,
            visible: true,
            lastUsedAt: new ServerDate().toISOString(),
          };
          const id = getMatchingAccountId({
            accounts,
            accountsToImport: dataToImport.accounts,
            item,
            domain,
            subdomain,
          });

          if (id) {
            const accountToUpdate: ItemData<AccountEntity> | undefined = dataToImport.accounts.find(
              account => (account.id === id),
            );
            // eslint-disable-next-line max-depth
            if (accountToUpdate) {
              // Account has been seen in the import batch
              // eslint-disable-next-line max-depth
              if (!accountToUpdate.data.subdomain) {
                accountToUpdate.data.subdomain = [];
              }
              accountToUpdate.data.subdomain.push(subdomainEntry);
            } else {
              // Account is already created so we need to update separately
              dataToImport.subdomains.push({
                ...subdomainEntry,
                id,
              });
            }
            dataToImport.mergeCount += 1;
          } else {
            dataToImport.accounts.push({
              ...item,
              data: {
                ...item.data,
                domain,
                subdomain: [
                  {
                    ...subdomainEntry,
                  },
                ],
              },
            });
          }
        } else {
          dataToImport.accounts.push(item);
        }
      } else if (itemType === IMPORT_ITEM_TYPE_NOTE) {
        dataToImport.notes.push(item);
        newNotes[item.id] = { id: item.id, ...item.data as NoteEntity };
      }
    }
  });

  const allNotes = { ...notes, ...newNotes };

  dataToImport.notes.forEach((note) => {
    if (!note.data.title) {
      const noteTitle = NoteNameHelper(allNotes, language);
      note.data.title = noteTitle;
      allNotes[note.id].title = noteTitle;
    }
  });

  return dataToImport;
};

export const triggerImport = () => async (
  dispatch: Dispatch<any>,
  getState: () => State,
  { syncInstance }: { syncInstance: Sync},
) => {
  const key = getRelevantUserKey(getState()) as string;
  const syncConfig = {
    sync: syncInstance,
    key,
  };
  const sync = getSyncInstance(syncInstance, key);

  const dataToImport = prepareDataToImport(getState());
  try {
    await SyncUtils.createBatch(sync, 'Account', dataToImport.accounts);
    await SyncUtils.createBatch(sync, 'Note', dataToImport.notes);
  } catch (e) {
    handleSyncError(e, 'triggerImport');
  }

  dataToImport.subdomains.forEach(({ id, subdomain, loginUrl }) => {
    dispatch(addSubdomain(syncConfig, id, subdomain, loginUrl));
  });
  const status = {
    accounts: dataToImport.accounts.length,
    notes: dataToImport.notes.length,
    merges: dataToImport.mergeCount,
  };
  dispatch({ type: IMPORTER_FINAL_STATUS_INFO, value: status });
};

export type TrackingData = {
  totalPasswords: number;
  inconsistentPasswords: number;
  duplicatedPasswords: number;
  suggestedPasswords: number;
  selectedPasswords: number;
  totalNotes: number;
  inconsistentNotes: number;
  duplicatedNotes: number;
  suggestedNotes: number;
  selectedNotes: number;
}

export const trackImportReferrer = (trackingData: TrackingData) => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(trackEvent(MP_IMPORT_IMPORT_OPEN, trackingData));
};

export const trackImportSource = (trackingData: TrackingData) => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(trackEvent(MP_IMPORT_IMPORT_SOURCE_SELECTED, trackingData));
};

export const trackImportSourceMapped = (trackingData: TrackingData) => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(trackEvent(MP_IMPORT_IMPORT_MAPPED, trackingData));
};

export const trackImportReviewd = (trackingData: TrackingData) => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(trackEvent(MP_IMPORT_REVIEWED, trackingData));
};
