import { ThunkAction } from 'redux-thunk';

import VaultSDK from '@nlok/vault-sdk';

import { migrate } from '@avira-pwm/sync/helpers/migration';
import OCLError from '@avira-pwm/sync/adapters/NDSAdapter/helpers/OCLError';

import { KeyDBStorage } from '@avira-pwm/storage';
import { sha256 } from '@avira-pwm/crypto-tools';
import { Status } from '@avira-pwm/services/licenseMigration';
import { RootState } from '../app/store';
import { ThunkExtraArgument } from '../app/thunk';

import asyncJsonStorage from '../lib/asyncJsonStorage';

import {
  NLOKClearKeys,
  NLOK_CLEAR_KEYS,
  NLOKClearMigrationAttempts,
  NLOK_CLEAR_MIGRATION_ATTEMPTS,
  NLOKIncreaseMigrationAttempts,
  NLOK_INCREASE_MIGRATION_ATTEMPTS,
  NLOKSetKeys,
  NLOK_SET_KEYS,
  NLOKSetMachineGuid,
  NLOK_SET_MACHINE_GUID,
} from './NLOKActionTypes';

import type { Actions } from './NLOKDataReducer';

import debug from '../debug';
import nlokHelper from './helper';
import { getVaultKeys } from './selectors';
import jwtDecode from '../lib/JWTDecoder';
import { trackMigrationStatusChanged } from './NLOKMigrationTrackingActions';

const log = debug.extend('NLOKActions');

export const setMachineGUID = (machineGuid: string): NLOKSetMachineGuid => ({
  type: NLOK_SET_MACHINE_GUID,
  value: machineGuid,
});

export const storeKeys = (
  encryptionKey: string | null,
  challengeKey: string | null,
  // obfuscationKey: string,
): NLOKSetKeys => ({
  type: NLOK_SET_KEYS,
  value: {
    encryptionKey,
    challengeKey,
  },
});

export const clearKeys = (): NLOKClearKeys => ({
  type: NLOK_CLEAR_KEYS,
});

export const increaseMigrationAttempts = (): NLOKIncreaseMigrationAttempts => ({
  type: NLOK_INCREASE_MIGRATION_ATTEMPTS,
});

export const clearMigrationAttempts = (): NLOKClearMigrationAttempts => ({
  type: NLOK_CLEAR_MIGRATION_ATTEMPTS,
});

export const storeVaultKeys = (): ThunkAction<
Promise<void>,
RootState,
ThunkExtraArgument,
Actions
> => async (dispatch, _getState) => {
  const { encryptionKey, challengeKey } = nlokHelper.getVaultKeys();

  if (!encryptionKey || !challengeKey) {
    dispatch(clearKeys());
    return;
  }

  dispatch(storeKeys(encryptionKey, challengeKey));
};

export const createVault = (password: string): ThunkAction<
Promise<void>,
RootState,
ThunkExtraArgument,
Actions
> => async () => {
  await nlokHelper.createVault(password);
};

export const deleteVault = (): ThunkAction<
Promise<void>,
RootState,
ThunkExtraArgument,
Actions
> => async () => {
  if (await nlokHelper.userHasVault()) {
    await nlokHelper.deleteVault();
  }
};

export const openVaultWithPassword = (password: string, createIfUnavailable = true): ThunkAction<
Promise<void>,
RootState,
ThunkExtraArgument,
Actions
> => async (dispatch) => {
  log('opening vault with password - create: %s', createIfUnavailable);

  if (!VaultSDK.vaultKeys.isLocked()) {
    log('vault already open; get keys and return');
    dispatch(storeVaultKeys());
    return;
  }

  if (createIfUnavailable) {
    await nlokHelper.openOrCreateVault(password);
  } else {
    await nlokHelper.openVaultWithPassword(password);
  }

  dispatch(storeVaultKeys());
};

export const tryOpenVault = (): ThunkAction<
Promise<void>,
RootState,
ThunkExtraArgument,
Actions
> => async (_dispatch, getState) => {
  log('opening vault with keys');

  const { encryptionKey, challengeKey } = getVaultKeys(getState());

  if (!VaultSDK.vaultKeys.isLocked()) {
    log('vault already open; return');
    return;
  }

  if (encryptionKey && challengeKey) {
    await nlokHelper.openVaultWithKeys(encryptionKey, challengeKey);
  } else {
    throw new Error('keys not present');
  }
};

export const closeVault = (): ThunkAction<
Promise<void>,
RootState,
ThunkExtraArgument,
Actions
> => async () => {
  await nlokHelper.closeVault();
};


const migrationLog = log.extend('migrate');

export const triggerMigrate = (
  oeToken: string,
  authToken: string,
  encryptionKey: string,
  fileEncryptionKey: string,
  vaultPassword: string,
): ThunkAction<
Promise<void>,
RootState,
ThunkExtraArgument,
Actions
// eslint-disable-next-line max-params
> => async (dispatch, _getState, { licenseService, s3FileService }) => {
  if (!encryptionKey || !oeToken || !authToken) {
    migrationLog('dependencies not present; bailing');
    throw new Error('missing deps; bailing');
  }

  migrationLog('starting migration attempt');

  const { aguid } = jwtDecode(oeToken);

  const ndsAdapterStorage = new KeyDBStorage(
    asyncJsonStorage,
    `NDS:${sha256(aguid)}`,
  );

  const statusUpdateCb = (
    error: Error | null,
    statusChanged: Status,
  ): Promise<void> | void => {
    if (error != null && error instanceof OCLError) {
      dispatch(trackMigrationStatusChanged(
        {
          status: statusChanged,
          errorCode: error.status,
        },
      ));
    } else {
      dispatch(trackMigrationStatusChanged(
        {
          status: statusChanged,
        },
      ));
    }
  };

  return migrate({
    licenseService,
    s3FileService,
    vaultSdk: VaultSDK,
    logger: migrationLog,
    nlokHelper,
    statusUpdateCb,
    ndsStore: ndsAdapterStorage,
  }, {
    aviraEncryptionKey: encryptionKey,
    aviraFileEncryptionKey: fileEncryptionKey,
    authToken,
    oeToken,
    vaultPassword,
  });
};
