import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  bool, object, func, string,
} from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import styled from 'pwm-components/styled';
import GetStartedDetailsPage from './GetStartedDetailsPage';
import { getStartedTilesSelector } from '../selectors';

import { addMilestone } from '../../user/UserActions';

import { getBrowser } from '../../lib/UserAgent';

import GetStartedOverviewPage from './GetStartedOverviewPage';

import { TrackingActions, MixpanelEvents } from '../../tracking';
import { getStartedConfig } from '../GetStartedHelper';

const {
  MP_GETSTARTED_POINT_EARNED,
} = MixpanelEvents;

const { trackEvent } = TrackingActions;

const PageContainer = styled('div')`
  width: 100%;
  height: 100%;
  min-width: 680px;
`;

const browserMap = {
  chrome: 'Google Chrome',
  firefox: 'Firefox',
  opera: 'Opera',
  edge: 'Microsoft Edge',
};

const browser = browserMap[getBrowser().toLowerCase()] || 'browser';

const tileIdToIndexMap = getStartedConfig.reduce((acc, { tileId }, index) => (
  {
    ...acc,
    [tileId]: index,
  }
), {});

const GetStartedContainer = ({
  data: { tiles, points, maxPoints },
  match,
  milestones,
  persistMilestone,
  language,
  isUnregisteredMode,
  extensionConnected,
  email,
  trackGetStartedPointEarned,
}) => {
  async function addGetStartedMilestone(name, tileName) {
    await persistMilestone(milestones[name]);

    const pointsEarned = points + 1;
    const tileCompleted = tiles[tileIdToIndexMap[tileName]].points + 1
      === tiles[tileIdToIndexMap[tileName]].maxPoints;

    trackGetStartedPointEarned(MP_GETSTARTED_POINT_EARNED, {
      pointsEarned,
      maxPoints,
      tileName,
      stepName: name,
      tileCompleted,
      getStartedCompleted: pointsEarned === maxPoints,
    });
  }

  return (
    <PageContainer>
      <Switch>
        <Route
          path={`${match.path}`}
          exact
          render={() => (
            <GetStartedOverviewPage
              browser={browser}
              tiles={tiles}
              tileUrlPrefix={match.url}
              points={points}
              maxPoints={maxPoints}
            />
          )}
        />

        {
          tiles.map(tile => (
            <Route
              key={tile.tileId}
              path={`${match.url}/${tile.link}`}
              render={() => (
                <GetStartedDetailsPage
                  {...tile}
                  onStepCompleted={addGetStartedMilestone}
                  language={language}
                  isUnregisteredMode={isUnregisteredMode}
                  browser={browser}
                  extensionConnected={extensionConnected}
                  email={email}
                />
              )}
            />
          ))
        }
      </Switch>
    </PageContainer>
  );
};

GetStartedContainer.propTypes = {
  data: object.isRequired,
  match: object.isRequired,
  milestones: object,
  persistMilestone: func.isRequired,
  isUnregisteredMode: bool.isRequired,
  language: string.isRequired,
  extensionConnected: bool.isRequired,
  email: string,
  trackGetStartedPointEarned: func.isRequired,
};

GetStartedContainer.defaultProps = {
  milestones: {},
  email: '',
};

const mapStateToProps = state => ({
  data: getStartedTilesSelector(state),
  milestones: state.dashboard.milestones,
  language: state.preferences.language,
  extensionConnected: state.dashboard.extensionConnected,
  email: state.oe.email,
  isUnregisteredMode: state.dashboard.isUnregisteredMode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  persistMilestone: addMilestone,
  trackGetStartedPointEarned: trackEvent,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GetStartedContainer),
);
